
module.exports = {
    prefFields: [
        'sms',
        'connectionsVisibility',
        'groupVisibility',
        'hideInterestPopup',
        'groupMsgNotifications',
        'digestInterval',
        'pendingConnections',
        'careerAlerts',
        'messageNotifications'
    ],
    profileFields: [
        'phone',
        'phoneCountry',
        'profileEmail'
    ]
};
