const _ = require('lodash');
const { default: Axios } = require('axios');

const Configuration = {

    nearpeerApi: {

        shouldUseMock: () => _.isUndefined(Configuration.nearpeerApi.baseUrl()),

        baseUrl: () => {

            if (process.env.API_HOST === 'USE-MOCK') {
                return undefined;
            }

            const host =   process.env.API_HOST   || 'https://staging.nearpeer.net';
            const prefix = process.env.API_PREFIX || '/api';

            // Seems like the 'host' is coming with double quotes surrounding it
            return String(`${host}${prefix}`).replaceAll('"', '');
        },
        makeHttp: () => {

            if (Configuration.nearpeerApi.shouldUseMock()) {

                return require('./mocks/nearpeer-http').makeNearPeerHttpMock();
            }

            return Axios.create({ baseURL: Configuration.nearpeerApi.baseUrl() });
        }
    },
    analyticsApi: {

        baseUrl: () => {

            const host =   process.env.ANALYTICS_API_HOST   || 'http://localhost:4444';
            const prefix = process.env.ANALYTICS_API_PREFIX || '/api';

            // Seems like the 'host' is coming with double quotes surrounding it
            return String(`${host}${prefix}`).replaceAll('"', '');
        },
        makeHttp: () => {

            return Axios.create({ baseURL: Configuration.analyticsApi.baseUrl() });
        }
    },
    twilioApi: {
        environmentPrefix: process.env.TWILIO_ENV || 'staging'
    },
    ipStackApi: {
        baseUrl: () => {

            const host = 'https://api.ipstack.com';

            // Seems like the 'host' is coming with double quotes surrounding it
            return String(`${host}`).replaceAll('"', '');
        },
        getDefaultParams: () => {

            return {
                access_key:process.env.IP_STACK_KEY
            };
        },
        makeHttp: () => {

            const instance = Axios.create({ baseURL: Configuration.ipStackApi.baseUrl() });
            instance.interceptors.request.use((config) => {

                config.params = {
                    // add your default ones
                    ...Configuration.ipStackApi.getDefaultParams(),
                    // spread the request's params
                    ...config.params
                };
                return config;
            });

            return instance;
        }
    },
    firebase: {
        senderId: process.env.FIREBASE_SENDER_ID
    }
};

module.exports = Configuration;
