const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { NavLink: Link } = require('react-router-dom');
const { default: Measure } = require('react-measure');
const { default: Avatar } = require('@mui/material/Avatar');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: TextField } = require('@mui/material/TextField');
const { default: SendIcon } = require('@mui/icons-material/Send');
const { default: ExpandLessIcon } = require('@mui/icons-material/ExpandLess');
const { default: ExpandMoreIcon } = require('@mui/icons-material/ExpandMore');
const { default: Paper } = require('@mui/material/Paper');
const { default: ArrowBack } = require('@mui/icons-material/ArrowBack');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: Button } = require('@mui/material/Button');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: Typography } = require('@mui/material/Typography');
const { default: List } = require('@mui/material/List');
const { default: ListItem } = require('@mui/material/ListItemButton');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: MuiAccordion } = require('@mui/material/Accordion');
const { default: AccordionDetails } = require('@mui/material/AccordionDetails');
const { default: AccordionSummary } = require('@mui/material/AccordionSummary');
const GroupHeader = require('../../classes/components/GroupHeader');
const { getSizedImageUrl } = require('utils/image');
const NoUserProfilePic = require('components/NoUserProfilePic');
const PassionItem = require('components/PassionInterestItem');
const { transient$Props } = require('utils/styles');

const Last = require('lodash/last');
const Debounce = require('lodash/debounce');
const IsEqual = require('lodash/isEqual');
// const { FixedSizeList } =  require('react-window');

const ChatList = require('./List');
const Colors = require('styles/colors.json');
const MentionsBase = require('../../containers/Chat/Mentions');
const ChatUtils = require('./utils');
const { default: Classes } = require('./styles.scss');
const { sendAnalyticsWithConfig, analyticsTemplates } = require('utils/analytics');
const NumUtils = require('utils/number');
const ScrollPortal = require('components/ScrollPortal');
const AlertDialog = require('containers/AlertDialog');
const { runProfanityCheck } = require('utils/profanityFilter');
const { ELEMENT_IDS } = require('utils/constants');

const FixMuiTextareaAutosizeAriaLabel = require('utils/fixMui4TextareaAutosizeAriaLabel');
const ChatItem = require('./Item');
const { USER_ROLE_IDS } = require('utils/constants');
const {
    makeSimilarityText,
    filterOutKeys,
    filterInKeys,
    formatSimilarityText: mainFormatSimilarityText
} = require('utils/makeUsersSimilarityText');
const { makeRandomInterestsText } = require('utils/makeRandomInterestsText');

const MESSAGE_CHAR_LIMIT = 1600;
const WARNING_THRESHOLD = 10;

const { createRef } = React;

const internals = {};
internals.textFieldHeight = 48;
internals.nameWrapperHeight = 48;
internals.chatInputWrapperVerticalPadding = 15;
internals.chatInputVerticalPadding = 4;

const WarningDiv = Styled.div`
  color: ${(props) => props.isOverLimit ? 'red' : 'orange'};
  margin-bottom: 8px;
  font-weight: bold;
  z-index: 100000;
  margin-left: 6px;
  font-size: 12px;
  background: none;
`;

const StyledAccordionSummary = Styled(AccordionSummary)`
    && {
        min-height: 35px;
        align-items: center;
    }
`;

const StyledList = Styled(List)`
  && {
    width: 100%;
    background-color: ${({ theme }) => theme.palette.background.paper};
    position: relative;
    overflow: auto;
    max-height: 150px;
    padding-top: 0;
  }
`;

const StyledListItem = Styled(ListItem)`
  && {
    cursor: pointer;
  }
`;

const StyledListRoot = Styled.div`
  && {
    width: 100%;
    background-color: ${(props) => props.theme.palette.background.paper};
  }
`;

const ConversationStartersList = (props) => {

    const { conversationStarters = [], onItemClick } = props;
    const { Accordion } = internals;

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (event, isExpanded) => {

        setExpanded(isExpanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
            >
                Conversation Starters
            </StyledAccordionSummary>
            <AccordionDetails>
                <StyledListRoot>
                    <StyledList dense={true}>
                        {conversationStarters.map(({ id, starterText }) => (

                            <StyledListItem button key={`starter-${id}`} onClick={() => {

                                analyticsTemplates.buttons('select conversation starter', `conversation starter: add conversation starter from dropdown menu  ${starterText || 'empty'}`, id);
                                onItemClick(starterText);
                                setExpanded(false);

                            }} >
                                <ListItemText primary={starterText} />
                            </StyledListItem>
                        ))}
                    </StyledList>
                </StyledListRoot>
            </AccordionDetails>
        </Accordion>
    );
};

ConversationStartersList.propTypes = {
    conversationStarters: T.array,
    onItemClick: T.func
};

class ChatFrame extends React.Component {

    static propTypes = {
        history: T.object.isRequired,
        onSubmitMessage: T.func.isRequired,
        onSaveMessage: T.func.isRequired,
        onModerateMessage: T.func.isRequired,
        onPinMessage: T.func.isRequired,
        onRemoveMessage: T.func.isRequired,
        onRemoveOwnMessage: T.func.isRequired,
        openAlertDialog: T.func.isRequired,
        onFlagInappropriate: T.func.isRequired,
        messages: T.array.isRequired,
        group: T.object.isRequired,
        users: T.array,
        userDetails: T.object,
        header: T.string,
        emptyChatInfo: T.string,
        localConversation: T.object,// eslint-disable-line react/no-unused-prop-types
        onSetLocalLastReadMessageIndex: T.func,// eslint-disable-line react/no-unused-prop-types
        setLastReadMessageIndex_byConversationSid: T.func,// eslint-disable-line react/no-unused-prop-types
        hasUnreadMessages: T.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
        onSetLastReadMessageIndex: T.func,    // eslint-disable-line react/no-unused-prop-types
        onRequestMessages: T.func,
        requestFirstPageMessages: T.func,
        ga: T.shape({
            onSubmitMessage: T.array,
            onTypingMessage: T.array
        }),
        isOffline: T.bool,
        includeEveryoneMention: T.bool,
        rolePermissions: T.shape({
            id: T.number,
            roleId: T.number,
            schoolId: T.number,
            homeText: T.string,
            canNotify: T.bool,
            canBatchNotify: T.bool,
            canPreapprove: T.bool,
            canDisable: T.bool,
            canCreateGroups: T.bool,
            canEditGroups: T.bool,
            canManageGroups: T.bool,
            canPlaceInModeration: T.bool,
            canPostInAnnouncement: T.bool,
            canSeeChatMessagesNoJoin: T.bool
        }),
        rolesInteractions: T.arrayOf(T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            schoolId: T.number,
            canViewProfile: T.bool,
            canViewInGroup: T.bool,
            canChat: T.bool,
            canChatWithoutConnection: T.bool,
            canSeeConnections: T.bool,
            canSeeUsersGroups: T.bool,
            canSeeSurveyFields: T.bool,
            canSeeExtendedProfile: T.bool
        })),
        role: T.shape({
            id: T.number,
            name: T.string,
            label: T.string
        }),
        showNotification: T.func,
        sid: T.string,
        variant: T.oneOf(['dm', 'group']),
        classId: T.any,
        isAnnouncement: T.bool,
        pinHeaderState: T.string,
        setPinHeaderState: T.func,
        useProfanityFilter: T.bool,
        conversationStarters: T.arrayOf(T.shape({
            id: T.number,
            starterText: T.string,
            schoolId: T.number
        })),
        userIsInClass: T.bool,
        currentUser: T.object.isRequired,
        categories: T.array,
        setCollapseHeaders: T.func,
        collapseHeaders: T.bool,
        getCurrentTwilioChannel: T.func.isRequired,
        twilioStartListening: T.func.isRequired
    }

    constructor(props) {

        super(props);

        this.state = {
            inputEl: null,
            badWordsDialogOpen: false,
            showPinMessages: props.pinHeaderState && props.pinHeaderState === 'open',
            mentionUsers: [],
            alertMessage: '',
            alertTitle: '',
            similarityText: '',
            chatTextStateValue: '',
            userLoading: false,
            optimisticMessages: [],
            headerWidth: 0,
            nameWidth: 0,
            chatInputFocused: false,
            badWordList: []
        };

        this.mentions = createRef();
        this.inputEl = createRef();

        this.saveMessage = this._saveMessage.bind(this);
        this.updateMessageFromInput = this._updateMessageFromInput.bind(this);
        this.updateMessageFromMention = this._updateMessageFromMention.bind(this);
        this.updateFoundUsers = this._updateFoundUsers.bind(this);
        this.setConversationStarterMsgToInput = this._setConversationStarterMsgToInput.bind(this);
        this.handleEnter = this._handleEnter.bind(this);
        this.handleBadWordAlertClose = this._handleBadWordAlertClose.bind(this);
        this.submitMessage = this._submitMessage.bind(this);
        this.toggleShowPinMessages = this._toggleShowPinMessages.bind(this);
        this.createFullSimilarityText = this._createFullSimilarityText.bind(this);
        this.markAllAsRead = Debounce(this._markAllAsRead, 300, { 'leading': true, 'trailing': true });
        this.goBack = this._goBack.bind(this);
        this.lastReadIndex = -1;
    }

    dynamicConfirmFunction = () => { };

    setDynamicConfirmFunction(newFunc) {

        this.dynamicConfirmFunction = newFunc;
    }

    openAlertDialogWithProps = ({ title, message, confirm, cancel, declineLabel, confirmLabel }) => {

        this.setState({
            alertTitle: title,
            alertMessage: message,
            alertDeclineLabel: declineLabel,
            alertConfirmLabel: confirmLabel
        });

        this.setDynamicConfirmFunction(confirm);

        // if (cancel) {
        //     this.setDynamicCancelFunction(cancel);
        // }

        this.props.openAlertDialog();
    }

    _updateMessageFromInput(event) {

        const message = event.target.value;

        this.setState({
            chatTextStateValue: message
        });
    }

    _setConversationStarterMsgToInput(convesationStarterText) {

        this.setState({
            chatTextStateValue: convesationStarterText
        });
    }

    _updateFoundUsers(foundUsers) {

        const newUsers = [
            ...this.state.mentionUsers,
            ...foundUsers
        ];

        const uniqueArr = [...new Map(newUsers.map((item) =>
            [item.id, item])).values()];

        this.setState({
            ...this.state,
            mentionUsers: uniqueArr
        });
    }

    _updateMessageFromMention(message, cb) {

        this.setState({
            chatTextStateValue: message
        }, cb);
    }

    _handleEnter(event) {

        let handled = false;

        if (this.mentions.current) {
            handled = this.mentions.current.handleKeyDown(event);
        }

        if (!handled && event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            this.submitMessage();
        }
    }

    _toggleShowPinMessages(event) {

        const { setPinHeaderState, sid } = this.props;

        const pinState = !this.state.showPinMessages === true ? 'open' : 'hide';
        this.setState({
            ...this.state,
            showPinMessages: !this.state.showPinMessages
        },
        () => setPinHeaderState(sid, pinState));
    }

    _submitMessage() {

        const {
            sid,
            ga,
            includeEveryoneMention,
            showNotification,
            messages,
            isAnnouncement,
            useProfanityFilter,
            currentUser
        } = this.props; // eslint-disable-line react/prop-types

        const { chatTextStateValue } = this.state; // eslint-disable-line react/prop-types

        if (chatTextStateValue && !this.props.isOffline) {

            if (chatTextStateValue.length > MESSAGE_CHAR_LIMIT) {

                return showNotification(`Message is too large at ${NumUtils.format.commas(chatTextStateValue.length)} characters. Max allowed is ${NumUtils.format.commas(MESSAGE_CHAR_LIMIT)}`);
            }

            const { passesCheck, badPhrases } = runProfanityCheck(chatTextStateValue);

            if (useProfanityFilter && (!passesCheck || badPhrases.length > 0)) {

                this.setState({
                    badWordsDialogOpen: true,
                    badWordList: badPhrases
                });

                return false;
            }

            const message = ChatUtils.editToFullBody(chatTextStateValue, {
                mentionMap: internals.mentionMap(this.state.mentionUsers, includeEveryoneMention)
            });

            if (!isAnnouncement) {
                this.props.onSubmitMessage({
                    channelSid: sid,
                    message
                });
            }
            else {
                // SEND LOCAL MESSAGE
                this.props.onSubmitMessage({
                    classId: sid,
                    message
                });
            }

            const { group } = this.props;

            const rando = String(Math.random()).slice(10);

            // setTimeout(() => {

            //     const msgIndex = this.state.optimisticMessages.findIndex(({ sid: _sid }) => {

            //         if (_sid === rando) {
            //             return true;
            //         }

            //         return false;
            //     });

            //     if (msgIndex > -1) {
            //         const clone = [...this.state.optimisticMessages];
            //         clone.splice(msgIndex, 1);
            //         this.setState({
            //             optimisticMessages: clone
            //         });
            //     }
            // }, 20000);

            this.setState({
                chatTextStateValue: '',
                optimisticMessages: [...this.state.optimisticMessages, {
                    sid: rando,
                    id: `optimistic-${rando}`,
                    channelSid: '',
                    classId: group?.id,
                    body: message,
                    timestamp: new Date(),
                    user: {
                        ...currentUser,
                        isMe: true
                    },
                    moderationStatus: 'ok',
                    isOptimistic: true,
                    isPinned: false,
                    isEdited: false
                }]
            });

            if (messages.length === 0 && !isAnnouncement) {
                this.props.requestFirstPageMessages();
            }

            if (ga?.onSubmitMessage) {
                sendAnalyticsWithConfig(ga.onSubmitMessage);
            }
        }
    }

    _handleBadWordAlertClose() {

        this.setState({
            badWordsDialogOpen: false,
            badWordList: []
        });
    }

    getRandomInterestsText = () => {

        /* const randomInterestsText = 'Random interests placeholder';*/

        // const interestsText = makeFirstInterestsText({
        //     passions: this.props.result.passionInterests,
        //     interests: this.props.result.interests,
        //     categories: this.props.categories,
        //     userName: this.props.result.firstName,
        //     isMe: false
        // });

        const hasData = this.props.categories
            && this.props.userDetails?.interests
            && this.props.userDetails?.user?.firstName;

        if (!hasData) {
            return '';
        }

        return makeRandomInterestsText({
            interests: { interests: this.props.userDetails?.interests },
            categories: this.props.categories,
            userName: this.props.userDetails?.user?.firstName,
            isMe: false
        });
    }

    _createFullSimilarityText() {

        let baseSimilarityText;
        let studentSimilarityText;

        const studentSimilarityTypes = [
            'majors',
            'transfer',
            'online'
        ];

        const {
            user,
            similarities
        } = this.props.userDetails;

        const { role: viewedProfileRole } = user || {};

        const isParent = USER_ROLE_IDS.PARENT.includes(viewedProfileRole.id);

        const hasSimilarities = !!similarities?.classes.length
            || !!similarities?.interests.length
            || !!similarities?.passionInterests.length
            || !!similarities?.majors.length;

        if (hasSimilarities) {
            if (isParent) {

                const { baseSimilarities, classSimilarities } = makeSimilarityText({
                    similarities: filterOutKeys(similarities, studentSimilarityTypes)
                });

                if (baseSimilarities || classSimilarities) {
                    baseSimilarityText = `${baseSimilarities ?? ''} ${classSimilarities ?? ''}`;
                }

                const { baseSimilarities: studentBaseSimilarities } = makeSimilarityText({
                    similarities: filterInKeys(similarities, studentSimilarityTypes),
                    areStartTxt: ' are'
                });
                studentSimilarityText = studentBaseSimilarities;
            }
            else {
                const { baseSimilarities, classSimilarities } = makeSimilarityText({
                    similarities
                });

                if (baseSimilarities || classSimilarities) {
                    baseSimilarityText = `${baseSimilarities ?? ''} ${classSimilarities ?? ''}`;
                }
            }
        }
        else {
            return this.setState({
                similarityText: this.getRandomInterestsText()
            });
        }

        const similarityText = this.formatSimilarityText(
            baseSimilarityText,
            studentSimilarityText,
            user.firstName,
            isParent
        );

        return this.setState({
            similarityText
        });
    }

    UNSAFE_componentWillMount() {

        if (this.props.variant === 'dm') {
            this.otherUser = this.props.users.find(({ id: _id }) => _id !== this.props.currentUser.id);
            this.createFullSimilarityText();
        }
    }

    async componentDidMount() {

        if (!this.props.isAnnouncement && this.props.sid !== this.props.getCurrentTwilioChannel()) {
            await this.props.twilioStartListening({
                channelSid: this.props.sid
            });
        }

        this.markAllAsRead(this.props);

        if (this.inputEl && this.inputEl.current) {
            FixMuiTextareaAutosizeAriaLabel(this.inputEl.current, 'type a message');
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        this.markAllAsRead(nextProps);

        let modifiedState = null;

        if (!IsEqual(nextProps.messages, this.props.messages)) {
            const pinnedMessages = nextProps && nextProps.messages && nextProps.messages.filter((message) => {

                if (message.isPinned) {
                    return true; // skip
                }

                return false;
            });

            if (!pinnedMessages.length) {
                modifiedState = {
                    ...modifiedState
                };
            }
            else {
                modifiedState = {
                    ...modifiedState
                };
            }
        }

        if (!IsEqual(nextProps.users, this.props.users)) {

            modifiedState = {
                ...modifiedState,
                mentionUsers: nextProps.users
            };
        }

        // Manage optimistic message state
        const { currentUser: { id: currentUserId } } = nextProps;
        const messagesEmpty = nextProps.messages.length === 0;

        if (currentUserId && !messagesEmpty) {
            const lastMsg = Last(nextProps.messages);
            const lastIndex = lastMsg?.index;

            // Check that there are optimistic messages, and that lastIndex is greater than our latest stored index.
            if (this.state.optimisticMessages.length > 0 && (lastIndex > this.lastReadIndex)) {
                const numNewMessages = lastIndex - this.lastReadIndex;
                // First msg is index 0
                const isFirstMsg = lastIndex === 0;
                // Get numNewMessages from end of new messages array.
                // Filter users for 'isMe', in case of concurrent messages from other users.
                let numOptimisticToRemove = 0;

                if (isFirstMsg && nextProps.messages[0]?.user?.isMe) {
                    numOptimisticToRemove = 1;
                }
                else {
                    numOptimisticToRemove = nextProps.messages
                    .slice(numNewMessages * -1)
                    // .filter(({ user }) => user?.isMe)
                    .length;
                }

                modifiedState = {
                    ...modifiedState,
                    optimisticMessages: this.state.optimisticMessages.slice(numOptimisticToRemove)
                };
            }

            // Set this.lastReadIndex even if no optimisticMessages
            this.lastReadIndex = lastIndex;
        }

        if (modifiedState !== null) {
            this.setState({
                ...this.state,
                ...modifiedState
            });
        }
    }

    formatSimilarityText(baseSimilarityText, studentSimilarityText, peerName, isParent) {

        let fullSimilarityText = '';
        if (baseSimilarityText || studentSimilarityText) {
            if (baseSimilarityText) {
                fullSimilarityText = `${baseSimilarityText} `;
            }

            if (studentSimilarityText) {
                if (isParent) {
                    fullSimilarityText = fullSimilarityText + `Your students${studentSimilarityText}`;
                }
                else {
                    fullSimilarityText = fullSimilarityText + `You and ${peerName}'s student ${studentSimilarityText}`;
                }
            }
        }

        return mainFormatSimilarityText(fullSimilarityText);
    }

    _markAllAsRead(props) {

        const {
            messages,
            hasUnreadMessages,
            onSetLastReadMessageIndex,
            setLastReadMessageIndex_byConversationSid,
            sid,
            localConversation,
            onSetLocalLastReadMessageIndex
        } = props;

        if (this.props.isAnnouncement) {
            if (localConversation && hasUnreadMessages) {
                onSetLocalLastReadMessageIndex({
                    conversationId: localConversation.id.toString()
                });
            }
        }
        else {
            if (hasUnreadMessages && messages && Last(messages)) {
                onSetLastReadMessageIndex({ messageIndex: Last(messages).index });
                setLastReadMessageIndex_byConversationSid({
                    conversationSid: sid,
                    messageIndex: Last(messages).index
                });
            }
        }
    }

    _goBack() {

        this.props.history.goBack();
    }

    _saveMessage({ editBody, ...rest }) {

        const {
            onSaveMessage,
            includeEveryoneMention,
            showNotification,
            useProfanityFilter
        } = this.props;

        if (editBody.length > MESSAGE_CHAR_LIMIT) {

            return showNotification(`Message is too large at ${NumUtils.format.commas(editBody.length)} characters. Max allowed is ${NumUtils.format.commas(MESSAGE_CHAR_LIMIT)}`);
        }

        const { passesCheck, badPhrases } = runProfanityCheck(editBody);

        if (useProfanityFilter && (!passesCheck || badPhrases.length > 0)) {

            this.setState({
                badWordsDialogOpen: true
            });

            return false;
        }

        if (this.props.isAnnouncement) {

            const { messageId, conversationId, classId } = rest;

            return onSaveMessage({
                messageId: messageId.toString(),
                conversationId: conversationId.toString(),
                classId: classId.toString(),
                body: ChatUtils.editToFullBody(editBody, {
                    mentionMap: internals.mentionMap(this.state.mentionUsers, includeEveryoneMention)
                })
            });
        }

        const { sid } = rest;
        return onSaveMessage({
            sid,
            body: ChatUtils.editToFullBody(editBody, {
                mentionMap: internals.mentionMap(this.state.mentionUsers, includeEveryoneMention)
            })
        });
    }

    setHeaderWidth = ({ bounds }) => {

        if (this.state.headerWidth !== bounds.width) {
            this.setState({ headerWidth: bounds.width });
        }
    }

    setNameWidth = ({ bounds }) => {

        if (this.state.nameWidth !== bounds.width) {
            this.setState({ nameWidth: bounds.width });
        }
    }

    render() {

        const {
            messages,
            onRequestMessages,
            onModerateMessage,
            onPinMessage,
            onRemoveMessage,
            onRemoveOwnMessage,
            onFlagInappropriate,
            openAlertDialog,
            header,
            group,
            users,
            includeEveryoneMention,
            isOffline,
            rolePermissions,
            rolesInteractions,
            showNotification,
            emptyChatInfo,
            isAnnouncement,
            classId,
            variant = 'group',
            conversationStarters,
            // TODO see if we can replace this with var
            // userBelongsToConversation
            userIsInClass,
            currentUser,
            categories,
            setCollapseHeaders,
            collapseHeaders
        } = this.props;

        const { id: currentUserId } = currentUser;

        const {
            ChatInput,
            ChatWrapper,
            ChatFrameWrapper,
            AnnouncementInfoChatWrapper,
            Mentions,
            IconBackButton,
            IconCollapseButton,
            HeaderExpandIcon,
            AvatarWrapper,
            PassionsHolder,
            HeaderTextWrapper,
            HeaderText,
            HeaderTextAvatarLockup
        } = internals;

        const {
            optimisticMessages,
            chatInputFocused,
            chatTextStateValue
        } = this.state;

        const showConversationStarters = variant === 'dm' && (conversationStarters && conversationStarters.length);

        const pinnedMessages = messages.filter((message) => {

            if (message.isPinned) {
                return true; // skip
            }

            return false;
        });

        const userBelongsToConversation = users.find((user) => {

            return user.id === currentUserId;
        });

        const userCanSendMessages = (variant === 'dm' && userBelongsToConversation)
            || (!isAnnouncement && variant === 'group' && userIsInClass)
            || (isAnnouncement && rolePermissions.canPostInAnnouncement && userIsInClass);

        const otherFirstLastName = this.otherUser ? `${this.otherUser.firstName} ${this.otherUser.lastName}` : '';

        const remainingChars = MESSAGE_CHAR_LIMIT - chatTextStateValue.length;
        const isNearLimit = remainingChars <= WARNING_THRESHOLD && remainingChars > 0;
        const isOverLimit = remainingChars < 0;

        return (
            <ChatFrameWrapper className={`${messages.length === 0 && optimisticMessages.length === 0 && Classes.noMessages}`}>
                <ScrollPortal top>
                    {variant === 'dm' && (
                        <Paper className={`${Classes.nameWrapper}`}>
                            <IconBackButton
                                aria-label='Go back'
                                onClick={this.goBack}
                                size='large'
                                data-focus-outline='radius:40,padding:-5'
                            >
                                <ArrowBack />
                            </IconBackButton>
                            <Measure
                                bounds
                                onResize={this.setHeaderWidth}
                            >
                                {({ measureRef }) =>

                                    <HeaderTextWrapper ref={measureRef}>
                                        <HeaderTextAvatarLockup>
                                            <AvatarWrapper>
                                                <Link
                                                    to={`/app/profile/${this.otherUser.id}`}
                                                    className={`${Classes.avatarLink}`}
                                                    data-focus-outline='radius:40,padding:2'
                                                    aria-label={`${this.otherUser.firstName} ${this.otherUser.lastName}'s profile`}
                                                >
                                                    {this.otherUser.croppedPicture ?
                                                        <Avatar
                                                            className={Classes.avatarImg}
                                                            src={getSizedImageUrl(this.otherUser.croppedPicture, 100)}
                                                            alt={otherFirstLastName}
                                                            size={60}
                                                        /> :
                                                        <Avatar
                                                            className={Classes.avatarImg}
                                                            alt={otherFirstLastName}
                                                            sizes={60}
                                                        >
                                                            <NoUserProfilePic iconColor={'#ffffff'} insideAvatar={true} outlined={false} />
                                                        </Avatar>}
                                                </Link>
                                            </AvatarWrapper>
                                            <Measure
                                                bounds
                                                onResize={this.setNameWidth}
                                            >
                                                {({ measureRef: nameMeasureRef }) =>

                                                    <HeaderText ref={nameMeasureRef}>
                                                        {header}
                                                    </HeaderText>
                                                }
                                            </Measure>
                                        </HeaderTextAvatarLockup>
                                    </HeaderTextWrapper>
                                }
                            </Measure>
                            {!collapseHeaders && !!this.otherUser.passionInterests.length && (
                                <PassionsHolder>
                                    {this.otherUser.passionInterests.map((passion) => {

                                        const svg = passion.svg || categories.find(({ id }) => id === passion.categoryId)?.svg;

                                        if (svg) {
                                            return <PassionItem key={passion.id} interest={passion} svg={svg} />;
                                        }
                                    })}
                                </PassionsHolder>
                            )}
                            {!collapseHeaders && <p className={`${Classes.similarityWrapper}`} dangerouslySetInnerHTML={{ __html: this.state.similarityText }} />}
                            <IconCollapseButton
                                onClick={() => setCollapseHeaders(!collapseHeaders)}
                                aria-label='Toggle collapse header'
                                data-focus-outline='radius:80'
                            >
                                <HeaderExpandIcon $headerIsCollapsed={collapseHeaders} />
                            </IconCollapseButton>
                        </Paper>
                    )}
                    {variant === 'group' && (
                        <GroupHeader
                            group={group}
                            variant='chat'
                            openAlertDialogWithProps={this.openAlertDialogWithProps}
                        />
                    )}
                </ScrollPortal>
                {!!pinnedMessages && pinnedMessages.length ? (
                    <ScrollPortal id={'pinMessagesWindow'} top>
                        <div className={Classes.pinnedMessagesWrapper}>
                            <h4 className={Classes.pinnedMessageHeader}>Pinned Messages</h4>
                            <Button
                                variant='contained'
                                color='secondary'
                                className={Classes.showPinMessagesBtn}
                                aria-label='Go back'
                                onClick={this.toggleShowPinMessages}
                                startIcon={this.props.pinHeaderState === 'open' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                data-focus-outline='radius:40,padding:3'
                            >
                                {this.props.pinHeaderState === 'open' ? 'Hide' : 'Show'}
                            </Button>
                        </div>

                        {this.props.pinHeaderState === 'open' && pinnedMessages.map((message) => {

                            return <Paper key={message.index} className={`${Classes.pinnedMessageWrapper} contentWrapper`}>
                                <ChatItem
                                    onSave={this.saveMessage}
                                    onModerate={onModerateMessage}
                                    onPin={onPinMessage}
                                    onRemove={onRemoveMessage}
                                    onRemoveOwn={onRemoveOwnMessage}
                                    onFlagInappropriate={onFlagInappropriate}
                                    updateAlertFunction={(newFunction, alertMessage, alertTitle) => {

                                        this.setDynamicConfirmFunction(newFunction);
                                        this.setState({
                                            alertMessage,
                                            alertTitle
                                        }, () => {

                                            openAlertDialog();
                                        });
                                    }}
                                    showModerationControls={true}
                                    showNotification={false}
                                    rolesInteractions={rolesInteractions}
                                    rolePermissions={rolePermissions}
                                    className={Classes.cf}
                                    message={message}
                                    isAnnouncement={isAnnouncement}
                                />
                            </Paper>;
                        })}
                    </ScrollPortal>
                ) : null}
                <AlertDialog
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    declineLabel={this.state.alertDeclineLabel}
                    confirmLabel={this.state.alertConfirmLabel}
                    confirmationCallback={() => {

                        this.dynamicConfirmFunction();
                    }}
                />
                {!!(messages.length || optimisticMessages.length) && <ChatList
                    className={Classes.chatList}
                    isAnnouncement={isAnnouncement}
                    messages={messages.concat(optimisticMessages)}
                    onRequestMessages={onRequestMessages}
                    onSaveMessage={this.saveMessage}
                    onModerateMessage={onModerateMessage}
                    onPinMessage={onPinMessage}
                    onRemoveMessage={onRemoveMessage}
                    onRemoveOwnMessage={onRemoveOwnMessage}
                    onFlagInappropriate={onFlagInappropriate}
                    showNotification={showNotification}
                    rolesInteractions={rolesInteractions}
                    rolePermissions={rolePermissions}
                    updateAlertFunction={(newFunction, alertMessage, alertTitle) => {

                        this.setDynamicConfirmFunction(newFunction);
                        this.setState({
                            alertMessage,
                            alertTitle
                        }, () => {

                            openAlertDialog();
                        });
                    }}
                    opennedPinWindow={this.props.pinHeaderState === 'open'}
                    showModerationControls={rolePermissions.canPlaceInModeration}
                />}

                {messages.length === 0 && optimisticMessages.length === 0 && (
                    <div className={Classes.infoNoMessages}>
                        {emptyChatInfo}
                    </div>
                )}

                <Dialog
                    open={this.state.badWordsDialogOpen}
                    onClose={this.handleBadWordAlertClose}
                    aria-labelledby='alert-Inappropriate-Message-title'
                    aria-describedby='alert-Inappropriate-Message-description'
                >
                    <DialogTitle id='alert-Inappropriate-Message-title'>{'Warning'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-Inappropriate-Message-description'>
                            Sorry, we detected inappropriate language in your message which violates community standards.
                            {this.state.badWordList && this.state.badWordList.length > 0 && (
                                <>
                                    <br /><br />
                                    The following word(s) are not allowed: <strong>{this.state.badWordList.join(', ')}</strong>
                                </>
                            )}
                            <br /><br />
                            Please edit your message.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleBadWordAlertClose} color='primary' autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <ScrollPortal bottom>
                    {(userCanSendMessages) ? <div>
                        <Mentions
                            ref={this.mentions}
                            className={`${Classes.mentionListWrapper} contentWrapper`}
                            message={this.state.chatTextStateValue}
                            inputEl={this.inputEl.current}
                            users={this.state.mentionUsers}
                            classId={classId}
                            variant={variant}
                            onRequestChange={this.updateMessageFromMention}
                            onSearchFinish={this.updateFoundUsers}
                            includeEveryoneMention={includeEveryoneMention}
                        />
                        {(isNearLimit || isOverLimit) && (
                            <WarningDiv isOverLimit={isOverLimit}>
                                {isOverLimit
                                    ? `Message is too long. Please remove ${Math.abs(remainingChars)} characters.`
                                    : `${remainingChars} characters remaining`}
                            </WarningDiv>
                        )}
                        <ChatWrapper
                            $isOffline={this.props.isOffline ? this.props.isOffline : false}
                            $chatInputFocused={chatInputFocused}
                        >
                            <ChatInput
                                id='chat-input'
                                aria-label='type a message'
                                placeholder='Type a message...'
                                style={{
                                    borderTopLeftRadius: 10,
                                    borderTopRightRadius: 10
                                }}
                                multiline
                                minRows={1}
                                maxRows={3}
                                inputRef={this.inputEl}
                                disabled={isOffline}
                                value={this.state.chatTextStateValue}
                                onKeyDown={this.handleEnter}
                                onChange={this.updateMessageFromInput}
                                inputProps={{
                                    style: {
                                        padding: `${internals.chatInputWrapperVerticalPadding}px 10px`
                                    }
                                }}
                                onFocus={() => this.setState({ chatInputFocused: true })}
                                onBlur={() => this.setState({ chatInputFocused: false })}
                            />
                            <IconButton
                                disabled={isOffline || isOverLimit}
                                onClick={this.submitMessage}
                                className={Classes.sendButton}
                                aria-label='send message'
                                size='large'
                                data-focus-outline='radius:60,padding:-6'
                            >
                                <SendIcon style={{ color: (isOverLimit || isOffline) ? 'grey' : Colors.muiPrimary }} />
                            </IconButton>
                        </ChatWrapper>
                    </div> : null}
                    {showConversationStarters && userCanSendMessages ? <ConversationStartersList conversationStarters={conversationStarters} onItemClick={this.setConversationStarterMsgToInput} /> : null}
                    {!userCanSendMessages ? <AnnouncementInfoChatWrapper
                        id={ELEMENT_IDS.chatTextfield}
                    >
                        <Typography
                            tabIndex={0}
                            className={Classes.announcementInfoChatText}
                            align='center'
                            data-focus-outline='radius:10,padding:3'
                        >
                            {isAnnouncement ? 'Announcements Only' : 'View-only Mode: join the group to chat'}
                        </Typography>
                    </AnnouncementInfoChatWrapper> : null}
                </ScrollPortal>
            </ChatFrameWrapper>
        );
    }
}

internals.ChatFrameWrapper = Styled.div`
    width: 100%;
`;

internals.ChatInput = Styled(TextField)`
    font: inherit;
    width: 100%;
    min-height: ${internals.textFieldHeight}px;
    padding-left: 15px;
    border: none;
    outline: none;
    resize: none;
    ::before{
        display: none;
    }
    ::after{
        display: none;
    }
`;

internals.ChatWrapper = Styled.div`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 49px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    left: 0;
    bottom: 0;
    background-color: white;
    border: 1px solid ${({ $chatInputFocused }) => $chatInputFocused ? '#4611a9' : '#bdbdbd'};

    // Ugh, linter is busted for this stuff
    &,
    * {
    background-color: ${({ $isOffline }) => {

        // #fafad2 is SASS lightgoldenrodyellow
        return $isOffline ? '#fafad2' : 'white';
    }}
    }
`;

internals.AnnouncementInfoChatWrapper = Styled.div`
    min-height: 62px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    left: 0;
    bottom: 0;
    align-items:center;
    justify-content:center;
    background-color: #f7f7f7;
    border: 1px solid #bdbdbd;
    border-bottom: none;
    border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-top-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

internals.Mentions = Styled(MentionsBase)`
    // Lining up the bottom of the mentions
    // list with the top of the chat border

    position: absolute;

    bottom: ${({ inputEl }) => {

        if (inputEl) {
            // +3 for border height
            return `${inputEl.offsetHeight
                + (internals.chatInputWrapperVerticalPadding * 2)
                + (internals.chatInputVerticalPadding * 2)
                + 3}px`;
        }

        return 0;
    }};
`;

internals.mentionMap = (users, includeEveryoneMention) => {

    const mentionMap = (users || []).reduce((collector, { id, firstName, lastName }) => {

        const name = [firstName, lastName].filter((x) => !!x).join(' ');

        return {
            ...collector,
            [name]: id
        };
    }, {});

    if (includeEveryoneMention) {
        mentionMap.everyone = null;
    }

    return mentionMap;
};

internals.IconBackButton = Styled(IconButton)`
    position: absolute;
    left: 8px;
    z-index: 99;
`;

internals.IconCollapseButton = Styled(IconButton)`
    position: absolute;
    top: 9px;
    right: 8px;
    width: 40px;
    height: 34px;
`;

internals.HeaderExpandIcon = Styled(ExpandLessIcon, transient$Props)`
    transform: rotate(${({ $headerIsCollapsed }) => $headerIsCollapsed ? '-180deg' : '0deg'});
    position: absolute;
    top: 5px;
    right: 8px;
    transition: transform 0.25s ease-in-out;
`;

internals.PassionsHolder = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-top: 10px;
    margin-bottom: -8px;
`;

internals.AvatarWrapper = Styled.div`
    left: 0;
    top: 5px;
    line-height: 1;
`;

internals.HeaderTextWrapper = Styled.div`
    width: 100%;
    text-align: center;
    position: relative;
`;

internals.HeaderText = Styled.div`
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 0;
`;

internals.HeaderTextAvatarLockup = Styled('div', transient$Props)`
    position: relative;
    font-weight: bold;
    width: calc(100% - 108px);
    left: 54px;
    line-height: 48px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 1.25em;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
`;

internals.Accordion = Styled(MuiAccordion)`
    && {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

module.exports = ChatFrame;
