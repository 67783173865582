const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: MuiList } = require('@mui/material/List');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemText } = require('@mui/material/ListItemText');
const FullScreenDialog = require('containers/FullScreenDialog');
const NotificationItem = require('./NotificationItem');
const { default: Classes } = require('./styles.scss');
const AlertDialog = require('containers/AlertDialog');
const { analyticsTemplates } = require('utils/analytics');

const internals = {};

module.exports = class NotificationsDialog extends React.Component {

    static propTypes = {
        onRequestClose: T.func.isRequired,
        onAcceptTransfer: T.func.isRequired,
        onRequestIgnore: T.func.isRequired,
        onRequestAccept: T.func.isRequired,
        openAlertDialog: T.func.isRequired,
        onOpen: T.func.isRequired,
        open: T.bool,
        notifications: T.arrayOf(T.shape({
            id: T.any.isRequired
        })),
        onClickJoinClass: T.func,
        showNotification: T.func,
        onClickAnswerQuestion: T.func,
        onDismissNotification: T.func,
        currentUserRolesInteractions:T.arrayOf(T.shape({
            id:T.number,
            name:T.string,
            label:T.string,
            schoolId:T.number,
            canViewProfile:T.bool
        }))
    }

    constructor() {

        super();

        this.state = {
            alertMessage:'',
            alertTitle:''
        };

        this.accept = this._accept.bind(this);
        this.ignore = this._ignore.bind(this);
        this.onClickJoinClass = this._onClickJoinClass.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.open && !this.props.open) {
            nextProps.onOpen(); // For example, to mark all notifications as read
        }
    }

    dynamicConfirmFunction = () => {};
    dynamicCancelFunction = () => {};

    setDynamicConfirmFunction(newFunc) {

        this.dynamicConfirmFunction = newFunc;

    }
    setDynamicCancelFunction(newFunc) {

        this.dynamicCancelFunction = newFunc;
    }
    _accept(notification) {

        this.props.onRequestAccept(notification);
    }

    _ignore(notification) {


        this.setState({
            alertMessage:`Are you sure you want to ignore the request from ${notification.sender.firstName}?`,
            alertTitle:`Ignore User request`
        });
        this.setDynamicConfirmFunction(() => {

            analyticsTemplates.buttons('confirm ignore request from user', 'profile: confirm ignore');
            this.props.onRequestIgnore(notification);

        });
        this.setDynamicCancelFunction(() => {

            analyticsTemplates.buttons('cancel ignore request from user', 'profile: cancel ignore');

        });
        this.props.openAlertDialog();
    }

    _onClickJoinClass({ id, name }) {

        this.props.onClickJoinClass({ id, name });
    }

    render() {

        const {
            onRequestClose,
            notifications,
            onAcceptTransfer,
            onClickAnswerQuestion,
            onDismissNotification,
            currentUserRolesInteractions,
            showNotification,
            open,
            // eslint-disable-next-line no-unused-vars
            onClickJoinClass,
            // eslint-disable-next-line no-unused-vars
            onRequestIgnore,
            // eslint-disable-next-line no-unused-vars
            onRequestAccept,
            // eslint-disable-next-line no-unused-vars
            onOpen,
            // eslint-disable-next-line no-unused-vars
            openAlertDialog,
            ...rest
        } = this.props;

        const { List } = internals;

        return <FullScreenDialog
            title={<span className={Classes.title}>Notifications</span>}
            onRequestClose={onRequestClose}
            open={open}
            {...rest}
        >
            <AlertDialog
                title={this.state.alertTitle}
                message={this.state.alertMessage}
                declineLabel='No'
                confirmationCallback={() => {

                    this.dynamicConfirmFunction();
                }}
                cancelCallback={() => {

                    this.dynamicCancelFunction();
                }}
            />
            <List className={Classes.list}>

                {!notifications.length && (
                    <ListItem>
                        <ListItemText
                            secondary='No new notifications!'
                        />
                    </ListItem>
                )}
                <div role='list'>
                    {notifications.reduce((collector, notification, i) => {

                        return collector.concat([
                            <NotificationItem
                                key={notification.id}
                                notification={notification}
                                onLeave={onRequestClose}
                                onAcceptTransfer={onAcceptTransfer}
                                onRequestIgnore={this.ignore}
                                onRequestAccept={this.accept}
                                onClickJoinClass={this.onClickJoinClass}
                                onClickAnswerQuestion={onClickAnswerQuestion}
                                onDismissNotification={onDismissNotification}
                                currentUserRolesInteractions={currentUserRolesInteractions}
                                showNotification={showNotification}
                                divider={i !== notifications.length - 1}
                            />
                        ]);
                    }, [])}
                </div>
            </List>
        </FullScreenDialog>;
    }
};

internals.List = Styled(MuiList)`
    padding: .5em 0em 100px;
    margin-left: -1em; // Lessen the left padding

    @media screen and (max-width: 600px) {
        margin-left: -2em;
        margin-right: -1em;
    }
`;
