const { default: Thunk } = require('redux-thunk');
const { routerMiddleware: RouterMiddleware } = require('connected-react-router');
const History = require('./history');
const AppContext = require('../app-context');
const { makeErrorHandler } = require('../utils/redux-helpers');
const { gaMiddleware } = require('../utils/analytics');

const handlers = AppContext.context.handlers.errorHandling;

// eslint-disable-next-line hapi/hapi-scope-start, hapi/no-arrowception
// const consoleLogger = (store) => (next) => (action) => {

//     console.group(`MIDDLEWARE ${action.type}`);
//     console.info('dispatching', action.type, action.payload);
//     const result = next(action);
//     const state = store.getState();
//     console.log('next state context', state.signup.context);
//     console.log('next state auth', state.auth);
//     console.groupEnd();
//     return result;
// };

module.exports = [
    makeErrorHandler({ onError: handlers.pushNotification }),
    Thunk,
    RouterMiddleware(History),
    gaMiddleware
    // consoleLogger
];
