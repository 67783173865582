const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: LinearProgress } = require('@mui/material/LinearProgress');
const { default: Typography } = require('@mui/material/Typography');

const internals = {};

module.exports = function LinearProgressWithLabel(props) {

    const { value } = props;

    const { FlexRow } = internals;

    return (
        <FlexRow>
            <div style={{ flexGrow: 1 }}>
                <LinearProgress
                    variant='determinate'
                    value={value}
                    style={{
                        height: 10,
                        borderRadius: 4
                    }}
                />
            </div>
            <div style={{
                minWidth: 55,
                textAlign: 'right'
            }}>
                <Typography variant='body2' color='text.secondary'>
                    {`${Math.round(value)}%`}</Typography>
            </div>
        </FlexRow>
    );
};

module.exports.propTypes = {
    value: T.number
};

internals.FlexRow = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`;
