const { connect } = require('react-redux');
const { context } = require('../app-context');
const { push: Push } = require('connected-react-router');
const AppLayout = require('../layouts/AppLayout');

const actions   = context.actions;
const selectors = context.selectors.all;

module.exports = connect(
    (state) => ({
        currentUser: selectors.getCurrentUser(state).user() || null,
        numUnreadNotifications: selectors.getUnreadNotificationsCount(state),
        schoolSlug: selectors.getCurrentSchoolSlug(state),
        hasResourcesPage: selectors.getCurrentSchoolHasResources(state),
        resourcesUrl: selectors.getCurrentSchoolResourcesUrl(state),
        isOffline: selectors.getIsOffline(state),
        notificationsOpen: selectors.getNotificationsOpen(state),
        logoutDialogOpen: selectors.getLogoutDialogOpen(state),
        modalOpen: selectors.getModalIsOpen(state),
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        role: selectors.getCurrentUserRole(state),
        rootLoading: selectors.getMainAppLoading(state),
        path: window.location.pathname,
        isSSOSchool: selectors.getIsSSOSchool(state)
    }), {
        onLogout: actions.auth.logout,
        openNotifications: actions.app.openNotifications,
        closeNotifications: actions.app.closeNotifications,
        openLogoutDialog: actions.app.openLogoutDialog,
        closeLogoutDialog: actions.app.closeLogoutDialog,
        navigate: Push
    }
)(AppLayout);
