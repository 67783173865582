const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const PropTypes = require('prop-types');
const { default: AppRate } = require('cordova-plugin-apprate');
const ClickOutside = require('react-click-outside');
const { default: Classes } = require('./styles.scss');
const { default: Paper } = require('@mui/material/Paper');
const { default: Typography } = require('@mui/material/Typography');
const PlayStore = require('static/google_play.svg');
const AppStore = require('static/app_store.svg');
const { analyticsTemplates } = require('utils/analytics');
const { getOriginalImageUrl } = require('utils/image');

const internals = {};

module.exports = class WelcomePage extends React.PureComponent {

    static propTypes = {
        isAvailableForAppReview: T.bool,
        onSubmitAppReviewStatus: T.func,
        push: T.func,
        rolePermissions: T.shape({
            id: T.number,
            roleId: T.number,
            schoolId: T.number,
            homeText: T.string
        }),
        schoolWelcomeText: T.string,
        schoolRoleWelcomeImgUrl: T.string,
        currentUser: T.object,
        schoolImg: T.string
    }

    constructor(props) {

        super();

        this.state = {
            calloutClosing: false,
            calloutClosed: false
        };

        this.closingCallout = this._closingCallout.bind(this);
        this.closeCallout = this._closeCallout.bind(this);
    }

    componentDidMount() {

        document.body.classList.add(Classes.welcomeBody);
    }

    componentDidUpdate(prevProps) {

        if (this.props && prevProps && (prevProps.isAvailableForAppReview !== this.props.isAvailableForAppReview)  && this.props.isAvailableForAppReview && window.cordova) {
            const apiHost = process.env.API_HOST;
            const iOSAppId = '1300243604';
            let androidAppName = '';

            if (apiHost.includes('staging.nearpeer.net')) {
                androidAppName = 'net.nearpeer.app_staging';
            }
            else if (apiHost.includes('nearpeer.net')) {
                androidAppName = 'net.nearpeer.app';
            }

            const { currentUser, push } = this.props;

            AppRate.setPreferences({
                promptAgainForEachNewVersion: false,

                reviewType: {
                    android: 'InAppBrowser',
                    ios: 'InAppBrowser'
                },
                storeAppURL: {
                    ios: iOSAppId,
                    android: `market://details?id=${androidAppName}`
                },
                customLocale: {
                    title: 'Review Nearpeer',
                    message: 'Your reviews help us grow the Nearpeer community!',
                    cancelButtonLabel: 'No Thanks',
                    laterButtonLabel: 'Remind Me Later',
                    rateButtonLabel: 'Rate Nearpeer Now',
                    yesButtonLabel: '😍 Loving It',
                    noButtonLabel: '🙁 Could be better',
                    appRatePromptTitle: `${currentUser.firstName}, we'd love to know if you're enjoying Nearpeer`,
                    feedbackPromptTitle: 'Your reviews help us grow the Nearpeer community!'
                },
                callbacks: {
                    onButtonClicked: (buttonIndex, buttonLabel, promptType) => {

                        const { onSubmitAppReviewStatus } = this.props;
                        if (promptType === 'AppRatingPrompt') {

                            if (buttonIndex === 1) {
                                //dispatch review status declined
                                analyticsTemplates.buttons('app review declined', 'app review: app rating prompt declined');
                                onSubmitAppReviewStatus('declined');
                                push('/app/bug-report');
                                return false;
                            }
                        }
                        else if (promptType === 'StoreRatingPrompt') {

                            if (buttonIndex === 1) {
                                //dispatch review status declined
                                //dispatch NO RATING
                                analyticsTemplates.buttons('app review declined', 'app review: store rating prompt declined');
                                return onSubmitAppReviewStatus('declined');
                            }

                            if (buttonIndex === 2) {
                                //dispatch review status postponed
                                //postpone ratings for  5 days
                                analyticsTemplates.buttons('app review postponed', 'app review: app rating postponed');
                                return onSubmitAppReviewStatus('postponed');
                            }

                            if (buttonIndex === 3) {
                                //dispatch review status accepted
                                //rate app and ser lastTimeAppReviewed
                                analyticsTemplates.buttons('app review accepted', 'app review: app rating prompt accepted');
                                return onSubmitAppReviewStatus('accepted');
                            }
                        }
                    }
                }
            });

            AppRate.promptForRating();
        }
    }

    componentWillUnmount() {

        document.body.classList.remove(Classes.welcomeBody);
    }

    _closingCallout() {

        this.setState({ calloutClosing: true });
    }

    _closeCallout() {

        this.setState({ calloutClosed: true });
    }

    renderTextWithLinks(text) {

        const parts = text.split(' ');

        const urlExp = /(\b(?:https?:\/\/)?[a-z0-9\-.]+\.[a-z]{2,24}(?:\/\S*)?\b)/gi;

        const matchesUrl = (maybeUrl) => {

            // If the string has 2 dots in a row, it's not a link.
            // The urlExp regex has trouble with this condition.
            // It will match hello...world, so let's not give it a chance to!
            if (maybeUrl.includes('..')) {
                return null;
            }

            return maybeUrl.match(urlExp);
        };

        const finalString =  parts.reduce((out, part,currentIndex) => {

            if (typeof part !== 'string') {
                return out.concat(part);
            }

            if (part === '<newline>') {

                if (parts.length > 1 && out.length >= 1) {
                    out[currentIndex - 1] = `${parts[currentIndex - 1]}\n `;

                    return out.concat('');
                }
            }

            part = part.concat(' ');

            const subparts = part.split(urlExp).map((url) => {

                if (!matchesUrl(url)) {
                    return url; // Not actually a url
                }

                return (
                    <a
                        key={url}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={Classes.itemLink}
                        href={internals.ensureProtocol(url)}
                        aria-label={`Link to ${url}`}
                        data-focus-outline='radius:5,padding:2,zIndex:1'
                    >
                        Click Here
                    </a>
                );
            });

            return out.concat(subparts);
        }, []);

        return finalString;
    }

    render() {

        // const { calloutClosed, calloutClosing } = this.state;

        const {
            rolePermissions,
            schoolWelcomeText,
            schoolImg,
            currentUser,
            schoolRoleWelcomeImgUrl
        } = this.props;

        const { isFirstDay, firstName } = currentUser ?? {};

        const {
            styles,
            HeaderText
        } = internals;

        const isTeamsApp = process.env.APP_FLAVOUR === 'teams';

        // const closed = calloutClosed ? Classes.closed : '';
        // const closing = calloutClosing ? Classes.closing : '';

        return <div className={Classes.wrapper}>
            <div className={Classes.main}>
                <HeaderText>{`Welcome${!isFirstDay ? ' Back,' : ','} ${firstName}!`}</HeaderText>
                {schoolImg && <div className={Classes.schoolImgContainer}><img alt={'school logo'} src={schoolImg} /></div>}
                <div className={Classes.welcomeMessage}>
                    {schoolWelcomeText ? <p style={{ whiteSpace: 'pre-wrap' }}>
                        {this.renderTextWithLinks(schoolWelcomeText)}
                    </p> : <div>
                        <span>Now it’s easy to find, connect, and meet your peers!</span>
                        <br />
                        <br />
                        <span>Life is better when you&apos;re part of the community!</span>
                    </div>
                    }
                    {schoolRoleWelcomeImgUrl && <div className={Classes.schoolRoleImgContainer}><img alt={'School role welcome image'} src={getOriginalImageUrl(schoolRoleWelcomeImgUrl)} /></div>}
                    {rolePermissions?.homeText && <React.Fragment><p className={Classes.roleWelcomeMessage}>{this.renderTextWithLinks(rolePermissions.homeText)}</p></React.Fragment>}
                </div>
                {!window.cordova &&
                    <Paper style={styles.appStorePaper} elevation={2}>
                        <div className={Classes.appStorePaperContent}>

                            <div className={Classes.getAppsButtons}>
                                {isTeamsApp ?
                                    <React.Fragment>
                                        <a
                                            className={Classes.getAppsButton}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            href='https://itunes.apple.com/us/app/nearpeer-for-teams/id6445984458'
                                        >
                                            <img alt={'App store logo'} src={AppStore} />
                                        </a>
                                        <a
                                            className={Classes.getAppsButton}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            href='https://play.google.com/store/apps/details?id=net.nearpeer.app_business'
                                        >
                                            <img alt={'Google Play logo'} src={PlayStore} />
                                        </a>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <a
                                            className={Classes.getAppsButton}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            href='https://itunes.apple.com/us/app/nearpeer-for-college-students/id1300243604'
                                            data-focus-outline='radius:5,padding:2,zIndex:1'
                                        >
                                            <img alt={'App store logo'} src={AppStore} />
                                        </a>
                                        <a
                                            className={Classes.getAppsButton}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            href='https://play.google.com/store/apps/details?id=net.nearpeer.app'
                                            data-focus-outline='radius:5,padding:2,zIndex:1'
                                        >
                                            <img alt={'Google Play logo'} src={PlayStore} />
                                        </a>
                                    </React.Fragment>
                                }
                            </div>
                            <span>Get the Nearpeer App today!</span>
                        </div>
                    </Paper>
                }
            </div>
        </div>;
    }
};

internals.ClickOutsideDiv = ClickOutside(class extends React.Component {

    static propTypes = {
        handleClickOutside: PropTypes.func.isRequired
    }

    static displayName = 'ClickOutsideDiv';

    constructor(props) {

        super(props);

        this.handleClickOutside = props.handleClickOutside;
    }

    componentDidMount() {

        document.addEventListener('touchstart', this.handleClickOutside, true);
    }

    componentWillUnmount() {

        document.removeEventListener('touchstart', this.handleClickOutside, true);
    }

    render() {

        return <div {...this.props} />;
    }
});

internals.HeaderText = Styled(Typography).attrs({
    variant: 'h2'
})`
    display: inline-block;
    border-bottom: 3px solid #a4e4d3;
    margin: 0.5rem auto;
    font-size: 30px;
    font-weight: bold;
`;

internals.styles = {
    appStorePaper: {
        maxWidth: 380,
        margin: 'auto',
        marginTop: 15,
        marginBottom: 15,
        padding: 20
    }
};

internals.ensureProtocol = (url) => {

    if (url.match(/^https?:\/\//i)) {
        return url;
    }

    return `http://${url}`;
};
