const Entry = require('./containers/Entry');
const SpecificSchoolEntry = require('./containers/SpecificSchoolEntry');
const SpecificParentEntry = require('./containers/SpecificParentEntry');
const VerifyEmail = require('./containers/VerifyEmail');
const ChoosePassword = require('./containers/ChoosePassword');
const UserInfo = require('./containers/UserInfo');
const { context } = require('app-context');
const PlainLayout = require('layouts/PlainLayout');
const { aliases, customSignups, customTeamSignups } = require('utils/customSignups');
const FallbackLoader = require('components/FallbackLoader');
const Storage = require('utils/storage');
const {
    getSignupContextWithQueryParams
} = require('utils/signup-context');

const redux = context.redux.hooks;
const selectors = context.selectors.all;
const actions   = context.actions;
const handlers = context.handlers;

const isTeamsApp = process.env.APP_FLAVOUR === 'teams';

const internals = {};

exports = module.exports = () => [{
    path: '/signup',
    component: PlainLayout,
    fallback: FallbackLoader,
    onWillMount: internals.signupPrecheck,
    childRoutes: [
        {
            path: '/verify-email',
            fallback: FallbackLoader,
            component: VerifyEmail
        },
        {
            path: '/choose-password',
            fallback: FallbackLoader,
            component: ChoosePassword,
            onWillMount: internals.choosePasswordPrecheck
        },
        {
            path: '/user-info',
            fallback: FallbackLoader,
            component: UserInfo,
            onWillMount: internals.userInfoPrecheck
        }
    ]
}];

exports.signupRoute = {
    path: '/signup',
    exact: true,
    component: Entry
};

exports.specificParent = {
    path: '/:slug/family',
    component: SpecificParentEntry,
    onWillMount: async ({ match: { params } }) => {

        const supported = {
            xula: true
        };

        const slug = String(params.slug).toLowerCase();

        if (!supported[slug]) {
            return { replace: '/signup' };
        }

        try {
            await handlers.signup.resolveSchoolExists(slug);
        }
        catch (err) {

            return { replace: '/signup' };
        }
    }
};

exports.specificSchool = {
    path: '/:slug',
    component: SpecificSchoolEntry,
    onWillMount: async ({ match: { params } }) => {

        let supported;

        if (isTeamsApp) {
            supported = customTeamSignups;
        }
        else {
            supported = customSignups;
        }

        const slug = String(aliases[params.slug] || params.slug).toLowerCase();

        if (!supported[slug]) {
            return { replace: '/signup' };
        }

        try {
            await handlers.signup.resolveSchoolExists(slug);
        }
        catch (err) {

            return { replace: '/signup' };
        }
    }
};

exports.initSignupContext = async () => {

    // Always merge query params in case new info
    const signupContext = await getSignupContextWithQueryParams(context);
    redux.dispatch(actions.signup.updateContext(signupContext));

    const {
        verificationToken,
        schoolId,
        email
    } = signupContext;

    if (verificationToken && schoolId) {
        const schoolExtension = selectors.getSchoolExtensionById(redux.getState(), schoolId);

        // Ensure current school details are fetched
        if (!schoolExtension) {
            // Fetch school details into redux
            await redux.dispatch(actions.dataFetching.fetchSchoolByVerificationToken({
                token: verificationToken,
                email
            }));
        }
    }

    return selectors.getSignupContext(redux.getState());
};

internals.signupPrecheck = async ({ location: { pathname } }) => {

    // Skip logic for verify-email route, which just relays info that an email was sent
    if (pathname.includes('verify-email')) {
        return;
    }

    const isVerified = selectors.getVerifiedStatus(redux.getState());

    if (isVerified) {
        return { replace: '/app/welcome' };
    }

    return await exports.initSignupContext();
};

// Users land here from their verification emails
internals.choosePasswordPrecheck = async () => {

    let state = redux.getState();

    const {
        verificationToken,
        schoolId,
        roleId,
        email,
        usingPassword,
        usingSSO
    } = selectors.getSignupContext(state);

    const hasLocalLoginToken = !!Storage.get('token');

    if (!verificationToken) {
        return { replace: '/login' };
    }

    try {
        await redux.dispatch(actions.signup.step2.checkPassword({
            verificationToken,
            email
        }));
    }
    catch (err) {
        // Ignore, handle in error-handling
    }

    // Refresh state after checkPassword
    state = redux.getState();

    const {
        isEmailVerified
    } = selectors.getSignupContext(state);

    // Get updated step 2 details
    const {
        hasSSOToken
    } = selectors.getSignupDetailsForStep2(state);

    const step2PrereqSatisfied = selectors.step2PrerequisiteSatisfied(state);

    if (usingPassword && isEmailVerified === false) {
        await redux.dispatch(actions.signup.verifyEmail({
            verificationToken
        }));
    }

    if (usingPassword && step2PrereqSatisfied) {
        return { replace: `/signup/user-info?v=${verificationToken}&email=${email}&s=${schoolId}&r=${roleId || 2}` };
    }
    else if (usingSSO && !(hasSSOToken && hasLocalLoginToken)) {
        return { replace: `/signup-sso?v=${verificationToken}&email=${email}&s=${schoolId}&r=${roleId || 2}` };
    }
    else if (usingSSO && hasSSOToken && hasLocalLoginToken) {
        return { replace: `/sso-terms?v=${verificationToken}&email=${email}&s=${schoolId}&r=${roleId || 2}` };
    }
};

internals.userInfoPrecheck = async () => {

    let state = redux.getState();

    const {
        verificationToken,
        schoolId,
        email
    } = selectors.getSignupContext(state);

    const {
        hasPassword,
        hasSSOToken
    } = selectors.getSignupDetailsForStep2(state);

    if (!verificationToken || !schoolId) {
        return { replace: '/login' };
    }

    const school = selectors.getSchoolById(redux.getState(), schoolId);

    // redux.dispatch(actions.dataFetching.fetchRoles())
    // console.log('userInfoPrecheck hasPassword', hasPassword);
    // console.log('userInfoPrecheck hasSSOToken', hasSSOToken);
    // console.log('userInfoPrecheck password', password);
    // console.log('userInfoPrecheck agreeToTerms', agreeToTerms);

    if (!hasPassword && !hasSSOToken) {
        // Get hasToken into redux from response
        await redux.dispatch(actions.signup.step2.checkPassword({
            verificationToken,
            email
        }));
    }

    if (!school) {
        // Fetch school into redux
        await redux.dispatch(actions.dataFetching.fetchSchoolByVerificationToken({
            token: verificationToken,
            email
        }));
    }

    // Update state after fetching hasSSOToken and hasPassword
    state = redux.getState();

    const step2PrereqSatisfied = selectors.step2PrerequisiteSatisfied(state);

    if (!step2PrereqSatisfied) {
        return { replace: '/signup/choose-password' };
    }

    await Promise.all([
        redux.dispatch(actions.signup.step3.fetchSignupProgress({
            verificationToken
        })),
        redux.dispatch(actions.dataFetching.fetchInterests()),
        // badge types
        redux.dispatch(actions.dataFetching.fetchBadgeTypes({ schoolId }))
    ]);

    await Promise.all([
        // years hired
        redux.dispatch(actions.dataFetching.fetchYearsHired({ schoolId })),
        // offices
        redux.dispatch(actions.dataFetching.fetchOffices({ schoolId }))
    ]);
};
