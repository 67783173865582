
const { context } = require('../app-context');

const selectors = context.selectors.all;
const { getUserId, setUserId, unsetUserId } = require('../utils/analytics');

module.exports = (store) => {

    store.subscribe(() => {

        const currentUser = selectors.getCurrentUser(store.getState()).user();

        if (currentUser) {
            if (getUserId() !== currentUser.id) {
                return setUserId(currentUser.id);
            }
        }
        else {
            unsetUserId();
        }
    });
};
