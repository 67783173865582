const T = require('prop-types');
const { makeActionCreator } = require('../utils/redux-helpers');
const { DATA_FETCHING: Types } = require('../action-types');
const { analyticsTemplates } = require('../utils/analytics');

const internals = {};

module.exports = (context) => {

    const selectors = context.selectors.all;
    const actions = context.actions;
    const api = context.api.nearpeer;
    const twilio = context.api.twilio;
    const redux = context.redux.hooks;

    const resultPayload = (payload) => ({ result: payload });

    const fetchUnknownMessageAuthors = ({ payload }) => {

        const state = redux.getState();

        const {
            entities: { messages },
            result: sids
        } = payload.result;

        const ids = sids
        .map((sid) => messages[sid].author)
        .filter((value, i, arr) => arr.indexOf(value) === i) // uniqueify
        .filter((id) => !selectors.userExists(state, id));

        if (!ids.length) {
            return;
        }

        redux.dispatch(actions.dataFetching.fetchUsers({ ids }));
    };

    return {
        fetchSchool: makeActionCreator(Types.FETCH_SCHOOL, { id: T.string }, { async: api.dataFetching.getSchool }),
        fetchSchoolByVerificationToken: makeActionCreator(Types.FETCH_SCHOOL_BY_TOKEN, { token: T.string, email: T.string }, { async: api.dataFetching.getSchoolByVerificationToken }),
        fetchLocalGroupConversation: makeActionCreator(Types.FETCH_GROUP_CONVERSATION, { id: T.string }, { async: api.communication.getLocalGroupConversation }),
        fetchLocalConversations: makeActionCreator(Types.FETCH_LOCAL_CONVERSATIONS, {}, { async: api.communication.getLocalConversations }),
        fetchUnreadLocalMessageCounts: makeActionCreator(Types.FETCH_UNREAD_LOCAL_MESSAGE_COUNTS, {}, { async: api.communication.getUnreadLocalMessageCounts }),
        fetchLocalMessageCounts: makeActionCreator(Types.FETCH_ALL_LOCAL_MESSAGE_COUNTS, {}, { async: api.communication.getLocalMessageCounts }),
        fetchLocalGroupConversationMessages: makeActionCreator(Types.FETCH_GROUP_CONVERSATION_MESSAGES, { id: T.string }, { async: api.communication.getLocalGroupConversationMessages }),
        fetchLocalConversationsUpdateDates: makeActionCreator(Types.FETCH_LOCAL_CONVERSATIONS_UPDATE_DATES, {}, { async: api.communication.getLocalConversationsUpdateDates }),
        fetchSchools: makeActionCreator(Types.FETCH_SCHOOLS, {}, { async: api.dataFetching.getSchools }),
        fetchInterests: makeActionCreator(Types.FETCH_INTERESTS, {}, { async: api.dataFetching.getInterests }),
        fetchRoleGroups: makeActionCreator(Types.FETCH_ROLE_GROUPS, {}, { async: api.dataFetching.getRoleGroups }),
        fetchRoles: makeActionCreator(Types.FETCH_ROLES, {}, { async: api.dataFetching.getRoles }),
        fetchCategories: makeActionCreator(Types.FETCH_CATEGORIES, {}, { async: api.dataFetching.getCategories }),
        fetchBadgeTypes: makeActionCreator(Types.FETCH_BADGE_TYPES, { schoolId: T.number }, { async: api.dataFetching.getBadges }),
        fetchConversationStarters: makeActionCreator(Types.FETCH_CONVERSATION_STARTERS, { schoolId: T.number }, { async: api.dataFetching.getConversationStarters }),
        fetchYearsHired: makeActionCreator(Types.FETCH_YEARS_HIRED, { schoolId: T.number }, { async: api.dataFetching.getYearsHired }),
        fetchOffices: makeActionCreator(Types.FETCH_OFFICES, { schoolId: T.number }, { async: api.dataFetching.getOffices }),
        fetchPreferences: makeActionCreator(Types.FETCH_USER_PREFERENCES, {}, { async: api.dataFetching.getUserPreferences }),
        fetchUsers: makeActionCreator(Types.FETCH_USERS, { ids: T.array }, { async: api.dataFetching.getUsers }),
        fetchUser: makeActionCreator(Types.FETCH_USER, { id: T.string }, { async: api.dataFetching.getUser }),
        fetchCurrentUser: makeActionCreator(Types.FETCH_CURRENT_USER, {}, {
            async: api.dataFetching.getCurrentUser ,
            after: () => actions.dataFetching.fetchRoles()
        }),
        fetchClassUserSearchResults:{
            firstPage: makeActionCreator(
                Types.FETCH_CLASS_USER_SEARCH_RESULTS.FIRST_PAGE,
                {
                    allByMatches: T.bool,
                    sortType: T.string,
                    loadedUsersIds: T.array,
                    viewAll: T.bool,
                    major: T.string,
                    interests: T.array,
                    passionInterests: T.array,
                    name: T.string,
                    age: T.number,
                    radius: T.number,
                    isOnline: T.bool,
                    isVeteran: T.bool,
                    isTransfer: T.bool,
                    fullTimeStatus: T.string,
                    locationType: T.oneOf(['housing', 'hometown']),
                    incomingClass: T.string,
                    graduatingClass: T.string,
                    classId: T.number,
                    includeStaff: T.bool,
                    staffTitle: T.string,
                    studentName: T.string,
                    contextFilter: T.string,
                    career: T.string,
                    profession: T.string,
                    bio: T.string,
                    title: T.string,
                    departmentId: T.number,
                    openSocial:T.bool,
                    workRemote:T.bool,
                    yearHiredId:T.number,
                    officeId:T.number,
                    badge: T.string
                },
                {
                    async: internals.getUserSearchAsyncFunc({
                        api,
                        selectors,
                        redux,
                        actions
                    }, { limit: 20 })
                }
            ),
            nextPage: makeActionCreator(Types.FETCH_CLASS_USER_SEARCH_RESULTS.NEXT_PAGE, { classId :T.number }, {
                async: ({ classId }) => {

                    const criteria = selectors.getNextPageCriteria_forClassSearch(redux.getState(),classId);

                    if (!criteria) {
                        return Promise.reject(new Error('Can\'t currently page through user search results'));
                    }

                    return api.dataFetching.getUserSearchResults(criteria);
                }
            }),
            mentions: makeActionCreator(Types.FETCH_CLASS_USER_SEARCH_RESULTS.MENTIONS, { classId :T.number,  name: T.string }, {
                async: ({ classId,name }) => {

                    return api.dataFetching.searchClassUsers_byName({ classId,name  });
                }
            })
        },
        fetchUserSearchResults: {
            firstPage: makeActionCreator(
                Types.FETCH_USER_SEARCH_RESULTS.FIRST_PAGE,
                {
                    allByMatches: T.bool,
                    sortType: T.string,
                    loadedUsersIds: T.array,
                    viewAll: T.bool,
                    major: T.arrayOf(T.oneOfType([T.number, T.string])),
                    interests: T.array,
                    passionInterests: T.array,
                    name: T.string,
                    age: T.number,
                    radius: T.number,
                    isOnline: T.bool,
                    isVeteran: T.bool,
                    isTransfer: T.bool,
                    searchIsParent: T.bool,
                    fullTimeStatus: T.string,
                    locationType: T.oneOf(['housing', 'hometown']),
                    incomingClass: T.string,
                    graduatingClass: T.string,
                    classId: T.number,
                    includeStaff: T.bool,
                    staffTitle: T.string,
                    studentName: T.string,
                    contextFilter: T.string,
                    career: T.string,
                    profession: T.string,
                    bio: T.string,
                    title: T.string,
                    departmentId: T.number,
                    openSocial:T.bool,
                    workRemote:T.bool,
                    yearHiredId:T.number,
                    officeId:T.number,
                    badge: T.string
                },
                {
                    async: internals.getUserSearchAsyncFunc({
                        api,
                        selectors,
                        redux,
                        actions
                    }, { limit: 20 })
                }
            ),
            nextPage: makeActionCreator(Types.FETCH_USER_SEARCH_RESULTS.NEXT_PAGE, {}, {
                async: () => {

                    const criteria = selectors.getNextPageCriteria_forUserSearchResults(redux.getState());

                    if (!criteria) {
                        return Promise.reject(new Error('Can\'t currently page through user search results'));
                    }

                    return api.dataFetching.getUserSearchResults(criteria);
                }
            })
        },
        fetchUserSearchNotifyResults:
            makeActionCreator(
                Types.FETCH_USER_SEARCH_NOTIFY_RESULTS,
                {
                    allByMatches: T.bool,
                    sortType: T.string,
                    loadedUsersIds: T.array,
                    viewAll: T.bool,
                    major: T.string,
                    interests: T.array,
                    passionInterests: T.array,
                    name: T.string,
                    age: T.number,
                    radius: T.number,
                    isOnline: T.bool,
                    isVeteran: T.bool,
                    isTransfer: T.bool,
                    fullTimeStatus: T.string,
                    locationType: T.oneOf(['housing', 'hometown']),
                    incomingClass: T.string,
                    graduatingClass: T.string,
                    classId: T.number,
                    includeStaff: T.bool,
                    staffTitle: T.string,
                    studentName: T.string,
                    contextFilter: T.string,
                    career: T.string,
                    profession: T.string,
                    bio: T.string,
                    title: T.string,
                    departmentId: T.number,
                    openSocial:T.bool,
                    workRemote:T.bool,
                    yearHiredId:T.number,
                    officeId:T.number,
                    badge: T.string
                },
                {
                    async: internals.getUserSearchAsyncFunc({
                        api,
                        selectors,
                        redux,
                        actions,
                        basicSearch: true,
                        searchType: 'notify',
                        contextFilterType: 'role'
                    }, { limit: -1 })
                }
            )
        ,
        fetchUserSearchSurveyResults:
            makeActionCreator(
                Types.FETCH_USER_SEARCH_SURVEY_RESULTS,
                {
                    allByMatches: T.bool,
                    sortType: T.string,
                    loadedUsersIds: T.array,
                    viewAll: T.bool,
                    major: T.string,
                    interests: T.array,
                    passionInterests: T.array,
                    name: T.string,
                    age: T.number,
                    radius: T.number,
                    isOnline: T.bool,
                    isVeteran: T.bool,
                    isTransfer: T.bool,
                    fullTimeStatus: T.string,
                    locationType: T.oneOf(['housing', 'hometown']),
                    incomingClass: T.string,
                    graduatingClass: T.string,
                    classId: T.number,
                    includeStaff: T.bool,
                    staffTitle: T.string,
                    studentName: T.string,
                    contextFilter: T.string,
                    career: T.string,
                    profession: T.string,
                    bio: T.string,
                    title: T.string,
                    departmentId: T.number,
                    openSocial:T.bool,
                    workRemote:T.bool,
                    yearHiredId:T.number,
                    officeId:T.number,
                    badge: T.string
                },
                {
                    async: internals.getUserSearchAsyncFunc({
                        api,
                        selectors,
                        redux,
                        actions,
                        basicSearch: true,
                        searchType: 'survey',
                        contextFilterType: 'role'
                    }, { limit: -1 })
                }
            )
        ,
        fetchChannel: makeActionCreator(Types.FETCH_CHANNEL, { channelSid: T.string }, { async: twilio.getConversation }),
        fetchChannels: makeActionCreator(Types.FETCH_CHANNELS, {}, { async: twilio.getChannels }),
        fetchUnreadMessageCounts: makeActionCreator(Types.FETCH_UNREAD_MESSAGE_COUNTS, {}, { async: twilio.getUnreadMessageCounts }),
        fetchMessageCounts: makeActionCreator(Types.FETCH_ALL_MESSAGE_COUNTS, {}, { async: twilio.getMessageCounts }),
        fetchChannelUpdateDates: makeActionCreator(Types.FETCH_CHANNEL_UPDATE_DATES, {}, { async: twilio.getConversationUpdateDates }),
        fetchChannelLastMessage: makeActionCreator(Types.FETCH_CHANNEL_LAST_MESSAGE, {}, { async: twilio.getConversationsLastMessage }),
        fetchMessages: {
            firstPage: makeActionCreator(Types.FETCH_MESSAGES.FIRST_PAGE, { channelSid: T.string }, { async: twilio.getMessages, after: fetchUnknownMessageAuthors }),
            nextPage: makeActionCreator(Types.FETCH_MESSAGES.NEXT_PAGE, { channelSid: T.string }, { async: twilio.getMoreMessages, after: fetchUnknownMessageAuthors })
        },
        subscribeMessages: {
            update: makeActionCreator(Types.SUBSCRIBE_MESSAGES.UPDATE, { entities: T.object, result: T.any }, { propTransform: resultPayload }),
            remove: makeActionCreator(Types.SUBSCRIBE_MESSAGES.REMOVE, { sid: T.string })
        },
        fetchNotifications: makeActionCreator(Types.FETCH_NOTIFICATIONS, {}, {
            async: api.dataFetching.getNotifications,
            after: () => actions.dataFetching.fetchCurrentUser()
        }),
        fetchClass: makeActionCreator(Types.FETCH_CLASS, { id: T.string }, { async: api.dataFetching.getClass }),
        fetchClasses: makeActionCreator(Types.FETCH_CLASSES, { managedUserId:T.number }, { async: api.dataFetching.getClasses }),
        fetchSurveys: makeActionCreator(Types.FETCH_SURVEYS, {}, { async: api.dataFetching.getSurveys }),
        fetchSurvey: makeActionCreator(Types.FETCH_SURVEY, { id: T.string, includeUserAnswers:T.bool }, { async: api.dataFetching.getSurvey }),
        fetchClassSearchResults: makeActionCreator(Types.FETCH_CLASS_SEARCH_RESULTS, { term: T.string, showAll: T.bool, managedUserId:T.number }, { async: api.dataFetching.getClassSearchResults })
    };
};

internals.getUserSearchAsyncFunc = (options, pagination) => {

    const {
        api,
        selectors,
        redux,
        actions,
        skipContextFilter,
        basicSearch,
        searchType,
        contextFilterType = 'roleGroup'
    } = options;

    return (props = {}) => {

        const state = redux.getState();

        let { allByMatches, sortType, viewAll, classId, ...criteria } = props;

        if (!criteria.radius || !criteria.locationType) {
            delete criteria.radius;
            delete criteria.locationType;
        }

        if (allByMatches) {
            criteria = {
                allByMatches: true,
                sortType
            };
        }
        else if (viewAll) {
            criteria = {};
        }

        criteria.classId = classId;

        const appContextFilter = selectors.getAppContextFilter(state);

        if (!skipContextFilter && appContextFilter !== 'all' && (criteria.contextFilter === null || criteria.contextFilter === undefined) ) {
            criteria.contextFilter = appContextFilter;
        }

        criteria.isBasicSearch = Boolean(basicSearch);
        criteria.contextFilterType = contextFilterType;

        if (!allByMatches && !classId && !basicSearch) {
            redux.dispatch(actions.app.setClassmateDiscoverSearchFilter({ filter : { ...criteria, sortType } }));
        }
        else if (!allByMatches && basicSearch) {

            if (searchType && searchType === 'notify') {
                redux.dispatch(actions.app.setNotifySearchFilter({ filter : { ...criteria, sortType } }));
            }
            else {
                redux.dispatch(actions.app.setSurveySearchFilter({ filter : { ...criteria, sortType } }));
            }
        }

        analyticsTemplates.buttons('peers sort option', `sort option: peers sort submit ${sortType ? sortType : ''}`);

        return api.dataFetching.getUserSearchResults(
            { ...criteria, sortType },
            pagination,
            basicSearch
        )
        .then((results) => {

            return Object.assign({ criteria }, results);
        });
    };
};
