const T = require('prop-types');
const { push: Push } = require('connected-react-router');
const { makeActionCreator } = require('../utils/redux-helpers');
const { AUTH: Types } = require('../action-types');
const NoRedirectRoutes = require('../routes/no-redirect-routes');

const internals = {};

module.exports = (context) => {

    const api       = context.api.nearpeer;
    const twilio    = context.api.twilio;
    const actions   = context.actions;
    const redux     = context.redux.hooks;
    const selectors = context.selectors.all;

    const cache = {};

    return {
        login: makeActionCreator(Types.LOGIN, { email: T.string, password: T.string, token: T.string }, {
            async: api.authentication.login,
            after: async ({ payload }) => {

                const { isVerified } = payload.result;

                const {
                    initializeApp,
                    initializeSignupInfo
                } = internals;

                // For users reauthing with a stored token
                if (isVerified) {
                    // To allow this route to be hit on refresh after login,
                    // or for use on the main login page, check the path and
                    // conditionally redirect.
                    if (
                        !window.location.pathname.startsWith('/app')
                        && !NoRedirectRoutes.includes(window.location.pathname)
                    ) {
                        redux.dispatch(Push('/app/welcome'));
                    }

                    return await initializeApp(context);
                }

                // isVerified === false
                // User hasn't finished signing up
                await initializeSignupInfo(context);
            }
        }),
        loginWithVerificationToken: makeActionCreator(Types.LOGIN_TOKEN, { verificationToken: T.string }, {
            async: api.authentication.loginWithVerificationToken,
            after: async () => {

                const { initializeApp } = internals;

                if (!NoRedirectRoutes.includes(window.location.pathname)) {
                    redux.dispatch(Push('/app/welcome'));
                }

                await initializeApp(context);
            }
        }),
        loginAuthZero: makeActionCreator(Types.LOGIN_AUTH_ZERO_TOKEN, { token: T.string }, {
            async: api.authentication.loginAuthZero,
            after: async ({ payload }) => {

                const { initializeApp } = internals;

                const {
                    isVerified
                } = payload.result;

                if (isVerified) {
                    redux.dispatch(Push('/app/welcome'));
                    return await initializeApp(context);
                }

                // User isn't verified yet, continue with signup
                return redux.dispatch(
                    Push('/sso-terms')
                );
            }
        }),
        logout: makeActionCreator(Types.LOGOUT, {}, {
            async: async () => {

                try {
                    const token = await selectors.getFirebaseToken(redux.getState());

                    if (typeof token !== 'undefined') {
                        await api.authentication.deleteFirebaseToken({ token });
                    }
                }
                catch (err) {

                    if (err) {
                        console.log(err);
                    }

                    // TODO resolve clearing twilio endpoint?  Probably if returns a 404 -- backend should probably catch this and handle it by decrementing the twilio endpoint count
                }

                const currentSchool = selectors.getCurrentSchool(redux.getState());

                cache.school = currentSchool;

                await api.authentication.logout();
            },
            after: async ({ dispatch }) => {

                dispatch(Push('/login'));

                dispatch(actions.app.setShouldRefreshSearch({
                    discover: true,
                    group: true
                }));

                await twilio.reset();

                if (cache.school.ssoFederatedLogout) {
                    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
                    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

                    // This iframe is just gonna hang out on the page. We'll leave it there.
                    const iframe = document.createElement('iframe');

                    // Set the URL of the iframe
                    iframe.src = `https://${domain}/v2/logout?client_id=${clientId}&returnTo=${encodeURIComponent(window.location.origin)}&federated=true`;

                    // Make the iframe invisible by setting CSS styles
                    iframe.style.width = '0px';
                    iframe.style.height = '0px';
                    iframe.style.border = 'none'; // Remove border
                    iframe.style.display = 'none'; // Alternatively, you can use `visibility: hidden;`

                    // Append the iframe to the body (so it loads)
                    document.body.appendChild(iframe);
                }

                delete cache.school;
            }
        }),
        closeAccount: makeActionCreator(Types.CLOSE_ACCOUNT, { closeAccountReason : T.string }, {
            async: ({ closeAccountReason }) => {

                //TODO create close account
                //change to remove firebase token
                return Promise.resolve(selectors.getFirebaseToken(redux.getState())).then((token) => {

                    if (typeof token !== 'undefined') {
                        return api.authentication.deleteFirebaseToken({ token });
                    }

                }).then(() => {

                    return api.authentication.closeAccount(closeAccountReason);

                }).then(() =>

                    undefined

                ).catch((err) => {

                    if (err) {
                        console.log(err);
                    }

                    // TODO resolve clearing twilio endpoint?  Probably if returns a 404 -- backend should probably catch this and handle it by decrementing the twilio endpoint count
                    return api.authentication.closeAccount(closeAccountReason);
                });
            },
            after: ({ dispatch }) => {

                dispatch(Push('/'));

                return twilio.reset();
            }
        }),
        accountIsClosedClearAll: makeActionCreator(Types.ACCOUNT_CLOSED_CLEAR, {}, {
            after: ({ dispatch }) => {

                dispatch(Push('/'));

                return twilio.reset();
            }
        }),
        requestPasswordReset: makeActionCreator(Types.REQUEST_PASSWORD_RESET, { email: T.string }, {
            async: api.authentication.requestPasswordReset
        }),
        resetPassword: makeActionCreator(Types.RESET_PASSWORD, { password: T.string, resetToken: T.string }, {
            async: api.authentication.resetPassword,
            after: ({ dispatch, payload }) => {

                dispatch(Push('/login'));
                dispatch(actions.alerts.notification.push({
                    message: 'Password was reset! Please login.'
                }));

                return payload;
            }
        }),
        checkResetToken: makeActionCreator(Types.CHECK_RESET_TOKEN, { resetToken: T.string }, {
            async: api.authentication.checkResetToken
        }),
        loginInputFailure: makeActionCreator(Types.LOGIN_INPUT_FAILURE, {}),
        updateTwilioToken: makeActionCreator(Types.UPDATE_TWILIO_TOKEN, {}, {
            async: api.authentication.updateTwilioToken,
            after: twilio.updateToken
        })
    };
};

internals.initializeApp = async (context) => {

    const twilio    = context.api.twilio;
    const actions   = context.actions;
    const redux     = context.redux.hooks;
    const selectors = context.selectors.all;

    const getCurrentSchoolId = () => selectors.getCurrentSchoolId(redux.getState());

    try {
        await twilio.initialize();
        await redux.dispatch(actions.dataFetching.fetchCurrentUser());

        await Promise.all([
            redux.dispatch(actions.dataFetching.fetchSchool({ id: getCurrentSchoolId() })),
            redux.dispatch(actions.dataFetching.fetchBadgeTypes({ schoolId: getCurrentSchoolId() }))
        ]);

        await Promise.all([
            redux.dispatch(actions.dataFetching.fetchConversationStarters({ schoolId: getCurrentSchoolId() })),
            redux.dispatch(actions.dataFetching.fetchYearsHired({ schoolId: getCurrentSchoolId() })),
            redux.dispatch(actions.dataFetching.fetchOffices({ schoolId: getCurrentSchoolId() }))
        ]);

        await Promise.all([
            redux.dispatch(actions.dataFetching.fetchRoles()),
            redux.dispatch(actions.dataFetching.fetchPreferences())
        ]);

        await Promise.all([
            redux.dispatch(actions.dataFetching.fetchInterests()),
            redux.dispatch(actions.dataFetching.fetchClasses())
        ]);
    }
    catch (err) {
        return redux.dispatch(actions.auth.logout());
    }

    redux.dispatch(actions.app.setMainAppLoading({
        appLoading: false
    }));
};

internals.initializeSignupInfo = async (context) => {

    const actions   = context.actions;
    const redux     = context.redux.hooks;
    const selectors = context.selectors.all;

    const { schoolId } = selectors.getSignupContext(redux.getState());

    if (schoolId) {
        await Promise.all([
            redux.dispatch(actions.dataFetching.fetchSchool({ id: schoolId })),
            // badge types
            redux.dispatch(actions.dataFetching.fetchBadgeTypes({ schoolId })),
            // years hired
            redux.dispatch(actions.dataFetching.fetchYearsHired({ schoolId })),
            // offices
            redux.dispatch(actions.dataFetching.fetchOffices({ schoolId }))
        ]);
    }

    redux.dispatch(actions.app.setMainAppLoading({
        appLoading: false
    }));
};
