const { connect } = require('react-redux');
const { context } = require('app-context');
const PreapprovedEmailUpload = require('../components/PreapprovedEmailUpload');

const api = context.api.nearpeer;
const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        schoolId: selectors.getCurrentSchoolId(state),
        schoolName: selectors.getCurrentSchoolName(state),
        schoolRoles: selectors.getFilteredSchoolRoles(state),
        api
    }), {
        onSubmit: actions.communication.uploadPreapproved,
        showToast: actions.alerts.notification.push,
        openAlertDialog: actions.app.openAlertDialog
    }
)(PreapprovedEmailUpload);
