
exports.USER_PERMISSIONS = {
    STUDENT: 'student',
    PARENT: 'parent',
    STAFF: 'staff',
    SUPER_USER: 'superuser'
};

// Any ids between 18-98 are reserved (inclusive)
exports.USER_ROLE_IDS = {
    SUPERUSER: [1, 11, 14, 18, 19, 20, 99],
    STUDENT: [2, 5, 7, 8, 9, 10, 12, 15, 17, 21, 22, 23],
    STAFF: [3, 13, 24, 25, 26],
    PARENT: [4, 16, 27, 28, 29]
};

exports.USER_ROLE_GROUPS_IDS = {
    STUDENT: 1,
    STAFF: 2,
    PARENT: 3,
    ALUMNUS: 4,
    EMPLOYER: 5
};

exports.USERS_SORT_TYPE = {
    BEST_MATCH: 'best_match',
    ALPHABETICAL: 'alphabetical',
    PASSION: 'passion_match',
    CONNECTION_STATUS: 'connection_status',
    HOMETOWN: 'hometown',
    MY_CONNECTIONS: 'my_connections',
    HOUSING: 'housing',
    NEWEST: 'newest',
    RANDOM: 'random',
    DISABLED: 'disabled'
};

exports.ALL_GROUPS_SORT_TYPE = {
    BY_NAME: 'name',
    BY_DATE_UPDATED: 'date_updated'
};

exports.MESSAGES_SORT_TYPE = {
    BY_NAME: 'name',
    BY_DATE_UPDATED: 'date_updated'
};

exports.INTERESTS = {
    LIMIT: 200
};

// 2 hours
exports.SEARCH_REFRESH_TIME_LIMIT = 2 * 60 * 60 * 1000;

exports.ELEMENT_IDS = {
    bottomButton: 'bottomButton',
    appBottomTabs: 'appBottomTabs',
    chatTextfield: 'chatTextfield',
    scrollPortalIds: {
        top: 'scrollPortalTop',
        bottom: 'scrollPortalBottom'
    },
    scrollPortalPaddingIds: {
        top: 'scrollPortalPaddingTop',
        bottom: 'scrollPortalPaddingBottom'
    },
    scrollContainer: 'scrollContainer',
    focusIndicator: 'focusIndicator'
};
