const { push: Push } = require('connected-react-router');
const { connect } = require('react-redux');
const { context } = require('app-context');
const UserPreferencesPage = require('../components/UserPreferencesPage');
const PrefsDomain = require('domain/preferences');
const { safeWarning } = require('assertions-simplified');
const Pick = require('lodash/pick');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        preferences: selectors.getPreferences__forUserPreferencesForm(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state),
        hideJobAlerts: selectors.getJobAlertPreferences_bySchoolId(state, selectors.getCurrentSchoolId(state))
    }), {
        onSubmit: (submitFields) => async (dispatch) => { // eslint-disable-line

            const profileFields = Pick(submitFields, PrefsDomain.profileFields);
            const prefFields = Pick(submitFields, PrefsDomain.prefFields);

            try {
                const success = await dispatch(actions.profileManagement.updateProfile(profileFields));

                // The action creator resolves undefined on failure
                if (!success) {
                    throw new Error('Update profile failed',{
                        cause: 'phone'
                    });
                }

                await dispatch(actions.profileManagement.updateUserPreferences(prefFields));

                dispatch(Push('/app/welcome'));

                dispatch(actions.alerts.notification.push({
                    message: 'Preferences saved!'
                }));
            }
            catch (err) {

                dispatch(actions.alerts.notification.push({
                    message: 'Error saving preferences'
                }));

                throw err;
            }
        },
        onSubmitNoRedirect: (submitFields) => (dispatch) => { // eslint-disable-line

            const profileFields = Pick(submitFields, PrefsDomain.profileFields);
            const prefFields = Pick(submitFields, PrefsDomain.prefFields);

            return dispatch(actions.profileManagement.updateProfile(profileFields))
            .then((success) => {

                // The action creator resolves undefined on failure
                if (!success) {
                    throw new Error('Update profile failed');
                }

                return dispatch(actions.profileManagement.updateUserPreferences(prefFields));
            })
            .then(() => {

                dispatch(actions.alerts.notification.push({
                    message: 'Preferences saved!'
                }));
            })
            .catch(safeWarning);
        },
        onInvalidSubmit: () => actions.alerts.notification.push({ message: 'Please fix invalid form fields' })
    }
)(UserPreferencesPage);
