const { CLASSES: Types } = require('../action-types');
const { ALL_GROUPS_SORT_TYPE } = require('../utils/constants');


const initialState = {
    allGroupsSortingType:ALL_GROUPS_SORT_TYPE.BY_DATE_UPDATED
};

const reducer = (state = initialState, action) => {

    const { id, text } = (action.payload || {});

    switch (action.type) {
        case Types.SET_SEARCH_TEXT:
            return {
                ...state,
                [id]: text
            };
        case Types.SET_ALL_GROUPS_SORTING_TYPE:
            return {
                ...state,
                allGroupsSortingType: action.payload.sortType
            };
        case Types.CLEAR_SEARCH_TEXT:
            delete state[id];
            return state;
        default:
            return state;
    }
};

const selectors = {
    getClassSearchTextById: (state, id) => state[id],
    getAllGroupsSortType: (state) => state.allGroupsSortingType
};

module.exports = Object.assign(reducer, { selectors });
