const { connect } = require('react-redux');
const { context } = require('app-context');
const DetailPage = require('../components/DetailPage');
const { goBack: GoBack, push: Push } = require('connected-react-router');
const { safeWarning } = require('assertions-simplified');

const selectors = context.selectors.all;
const actions   = context.actions;
const redux     = context.redux.hooks;

module.exports = connect(
    (state, { match: { params: { userId } } }) => ({
        key: userId,
        userDetails: selectors.getUser_forDetails(state, userId),
        currentUser: selectors.getCurrentUser(state)?.user(),
        currentUserName: selectors.getCurrentUser(state)?.user()?.firstName,
        currentUserId: selectors.getCurrentUser(state)?.user()?.id,
        interestsLoading: selectors.getIsLoadingInterests(state),
        actingUserRole: selectors.getUserRole(state, userId),
        selectedUserIsStudentGroup: selectors.isStudentRoleGroup(state, userId),
        categories: selectors.getCategories(state),
        currentUserClasses: selectors.getCurrentUser(state).classes() || [],
        currentUserInterests: selectors.getCurrentUserInterests(state) || [],
        conditionalDisplayFields: selectors.getConditionalDisplayFields_forUser(state, userId),
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        rolesInteractions:selectors.getCurrentUserRoleInteractions(state),
        role: selectors.getCurrentUserRole(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state),
        badges: selectors.getAllBadges(state)
    }),
    {
        onClickChat: (userId, message) => actions.communication.requestChat({ userId, message }),
        onClickConnect: (userId, message, isAutoConnect, similarityText) => actions.communication.requestPeer({ userId, message, isAutoConnect, similarityText }),
        onRequestDisconnect: ({ id: userId, firstName }) => actions.communication.disconnect({ userId, firstName }),
        onAcceptPeer: ({ id: userId, firstName }) => actions.communication.acceptPeer({ userId, firstName }),
        openAlertDialog: actions.app.openAlertDialog,
        onEnableUser: ({ id: userId }, toEnable) => actions.communication.enableUser({ userId, toEnable }),
        goBack: GoBack,
        push: Push,
        showNotification: (msg) => actions.alerts.notification.push({ message: msg }),
        saveProfilePicture: (profilePictureDetails) => (dispatch, getState) => { // eslint-disable-line

            const profilePictureDiff = selectors.getUserProfilePictureDiff_forUpdateProfile(redux.getState(), profilePictureDetails);

            dispatch(actions.app.setShouldRefreshSearch({ discover: true, group: true }));

            return dispatch(actions.profileManagement.updateProfilePicture(profilePictureDiff))
            .then((success) => {

                // The action creator resolves undefined on failure
                if (!success) {
                    throw new Error('Update profile picture failed');
                }

                dispatch(actions.alerts.notification.push({
                    message: 'Profile picture updated'
                }));

                return success;
            })
            .catch(safeWarning);
        }
    }
)(DetailPage);
