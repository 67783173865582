const { connect } = require('react-redux');
const { context } = require('app-context');
const MyClassesPage = require('../components/MyClassesPage');

const selectors = context.selectors.all;
const actions = context.actions;

const updateSortPreference = (sortType) => {
    return function (dispatch) {
        return dispatch(actions.profileManagement.updateUserPreferences({
            sortGroupsBy: sortType
        }))
        .catch((error) => {
            console.error('Failed to update sort preference:', error);
        });
    };
};

module.exports = connect(
    (state) => ({
        classes: selectors.getClasses_forMyClasses(state) || [],
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        role: selectors.getCurrentUserRole(state),
        allGroupsSortType: selectors.getSortGroupsByPreference(state),
        appContextFilter: selectors.getAppContextFilter(state)
    }),
    {
        onRequestRemoveClass: actions.classes.leave,
        setAllGroupSortType: actions.classes.setAllGroupSortType,
        updateSortPreference
    }
)(MyClassesPage);
