exports.run = (store) => {

    const initializers = [
        require('./app-context'),
        require('./connectivity'),
        require('./auth'),
        require('./fetch-data'),
        require('./notifications'),
        require('./analytics'),
        require('./cordova'),
        require('./google-places'),
        require('./watch-history'),
        require('./chats'),
        require('./signup')
    ];

    initializers.forEach((init) => init(store));
};
