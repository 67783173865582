const { connect } = require('react-redux');
const { context } = require('app-context');
const ClassmateSearchPage = require('../components/ClassmateSearchPage');
const { analyticsTemplates } = require('utils/analytics');

const selectors = context.selectors.all;
const actions = context.actions;

const internals = {};

module.exports = connect(
    (state) => ({
        results: selectors.getUsers_forUserSearch(state) || [],
        interestsLoading: selectors.getIsLoadingInterests(state),
        initLoading: selectors.getIsInitLoading_forUserSearch(state),
        loading: selectors.getIsLoading_forUserSearch(state),
        loadingNextPage: selectors.getIsLoadingNextPage_forUserSearch(state),
        hasPassionInterests: selectors.currentUserHasPassionInterests(state),
        showLoadMore: selectors.getShowLoadMore_forUserSearch(state),
        discoverSortType: selectors.getDiscoverSortType(state),
        displayAsList: selectors.getDisplayAsList_forUserSearch(state),
        canSearchHousing: selectors.getCanSearchHousing_forUserSearch(state),
        canSearchHometown: selectors.getCanSearchHometown_forUserSearch(state),
        lastSearch: selectors.getLastUserSearch(state),
        interestsActiveFilter: selectors.getInterestsActiveFilter(state),
        isParent: selectors.isParentPermission(state),
        badges: selectors.getAllBadges(state),
        rolePermissions:selectors.getCurrentUserRolePermissions(state),
        classmateDiscoverSearchFilter:selectors.getClassmateDiscoverSearchFilter(state),
        departments:selectors.getDepartmentsList_forProfileEditPage(state),
        yearsHiredList: selectors.getAllYearsHired(state),
        schoolRoleGroups: selectors.getRoleGroups(state),
        officesList: selectors.getAllOffices(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state),
        searching: false, // TODO Hmmm! Still need a way to express this
        majors: selectors.getMajorsList_forCurrentUser(state)
    }),
    {
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        onClickChangeSortType: (sortType) => (dispatch) => {

            dispatch(actions.app.setDiscoverSortType({ type: sortType }));
            return dispatch(actions.dataFetching.fetchUserSearchResults.firstPage({ allByMatches: true,sortType }));
        }, // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        onClickResetSearchFilters: (sortType) => (dispatch) => {

            dispatch(actions.app.setClassmateDiscoverSearchFilter({ filter : { } }));
            dispatch(actions.app.setAppContextFilter({ filter: 'all' }));
            return dispatch(actions.dataFetching.fetchUserSearchResults.firstPage({ allByMatches: true,sortType }));
        },
        onClickViewAll: () => actions.dataFetching.fetchUserSearchResults.firstPage({ viewAll: true }),
        onClickLoadMore: actions.dataFetching.fetchUserSearchResults.nextPage,
        // NOTE I wish I could easily DRY this with the other 'onSubmitSearch'
        // in 'ClassDetailPage', but the funcs are slightly different at the bottom
        onSubmitSearch: (criteria,sortType) => (dispatch) => { // eslint-disable-line hapi/no-arrowception, hapi/hapi-scope-start

            const filteredCriteria = criteria ? internals.filterOutEmpty(criteria) : [];

            const searchKeys = criteria ? Object.keys(filteredCriteria) : [];

            // Default back to 'allByMatches: true' if 'includeStaff' is true
            if (searchKeys.length === 1 && filteredCriteria.includeStaff) {
                delete filteredCriteria.includeStaff;
            }

            analyticsTemplates.buttons('submit peers search', `discover: search submit ${searchKeys.length ? searchKeys : 'empty'}`);

            if (searchKeys.length === 0) {
                return dispatch(actions.dataFetching.fetchUserSearchResults.firstPage({
                    allByMatches: true,
                    sortType
                }));
            }

            const { interests, ...others } = filteredCriteria;

            const search = { ...others };

            if (interests) {
                search.interests = interests.map((x) => x.id);
            }

            return dispatch(actions.dataFetching.fetchUserSearchResults.firstPage( { ...search,sortType }));
        },
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        rememberScroll: () => (dispatch) => {

            return dispatch(actions.app.rememberScroll({ type: 'discover' }));
        },
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        restoreScroll: () => (dispatch) => {

            return dispatch(actions.app.restoreScroll({ type: 'discover' }));
        }
    }
)(ClassmateSearchPage);

internals.filterOutEmpty = (obj) => {

    const { isBool } = internals;

    return Object.keys(obj).filter((key) => {

        const value = obj[key];

        if (Array.isArray(value)) {
            return !!value.length;
        }

        return isBool(value) || !!value;
    })
    .reduce((collector, key) => {

        collector[key] = obj[key];
        return collector;
    }, {});
};

internals.isBool = (maybeBool) => {

    if (String(maybeBool) === 'true' || String(maybeBool) === 'false') {
        return true;
    }

    return false;
};
