const ProfanityFilter = require('leo-profanity');

const BadWords = require('static/bad_words_dictionary.json');
const AllowedBadWords = require('static/allowed_bad_words_dictionary.json');

ProfanityFilter.add(BadWords);
ProfanityFilter.remove(AllowedBadWords);

const ProfanityList = ProfanityFilter.list();

const checkStringMultipleBadWords = (str) => {

    const foundBadWords = [];

    if (!str) {
        return foundBadWords;
    }

    let i = 0;

    // Casts str .toLowerCase()
    str = clearTextCompletely(str);

    // convert into array and remove white space
    // add default returned value for some cases (e.g. "." will returns null)
    const strs = str.match(/[^ ]+/g) || [];

    while (i <= ProfanityList.length - 1) {
        if (strs.includes(ProfanityList[i].toLowerCase())) {
            foundBadWords.push(ProfanityList[i]);
        }

        const multipleSizeWords = ProfanityList[i].split(' ');

        if (multipleSizeWords.length > 1) {
            if (str.includes(ProfanityList[i].toLowerCase())) {
                foundBadWords.push(ProfanityList[i]);
            }
        }

        i++;
    }

    return foundBadWords;
};

const removePunctuations = (text) => {

    const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/gi;

    return text.replace(punctuationRegex, '');
};

const removeNewLines = (text) => {

    const newLineRegex = /[\r\n]+/gim;

    // Cover case of vertical profanity
    return text.replace(newLineRegex, '') + ' ' + text.replace(newLineRegex, ' ');
};

const removeMultipleSpaces = (text) => {

    const multipleSpaceRegex = /\s+/gim;

    return text.replace(multipleSpaceRegex, ' ');
};

const clearTextCompletely = (text) => {

    let clearText = removePunctuations(text);
    clearText = removeNewLines(clearText);
    clearText = removeMultipleSpaces(clearText);

    return clearText.toLowerCase();
};

const runProfanityCheck = (str) => {

    const clearText = clearTextCompletely(str);
    const badPhrases = checkStringMultipleBadWords(clearText, ProfanityList);

    const passesCheck = !ProfanityFilter.check(clearText);

    return {
        passesCheck,
        badPhrases
    };
};

module.exports = {
    checkStringMultipleBadWords,
    removePunctuations,
    removeNewLines,
    removeMultipleSpaces,
    clearTextCompletely,
    runProfanityCheck
};
