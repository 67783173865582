const { useMemo, useRef } = require('react');
const { Auth0Client } = require('@auth0/auth0-spa-js');
const { useDispatch } = require('react-redux');
const { push: Push } = require('connected-react-router');

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const scope = process.env.REACT_APP_AUTH0_SCOPE;

const InAppBrowser = window.cordova?.InAppBrowser;
const shouldAutoRedirect = true;

exports.useAuth0 = function useAuth0(props) {

    const {
        redirectUri,
        cordovaCallback,
        email
    } = props || {};

    let { sso } = props || {};

    sso = sso || [];

    const browserWindowRef = useRef(null);
    const dispatch = useDispatch();

    const auth0Client = useMemo(() => {

        return new Auth0Client({
            domain,
            clientId,
            useRefreshTokens: true,
            cacheLocation: 'localstorage',
            authorizationParams: {
                redirect_uri: redirectUri,
                scope
            }
        });
    }, []);

    const logout = async () => {

        return await auth0Client.logout({
            onRedirect: false,
            openUrl: false,
            federated: true
        });
    };

    const redirectToLogin = async () => {

        // Logout to avoid leftover state conflicts, resulting in invalid state
        await logout();
        dispatch(Push('/login'));
    };

    const login = useMemo(() => {

        const openInBrowser = (url) => {

            if (!window.cordova) {
                window.location = url;
            }
            else {
                browserWindowRef.current?.close();
                browserWindowRef.current = InAppBrowser.open(
                    url,
                    '_blank',
                    'location=no,clearsessioncache=no,clearcache=no' // ,zoom=no (for android)
                );

                browserWindowRef.current.addEventListener('loadstart', (evt) => {

                    const isFinalRedirect = evt.url.startsWith('https://nearpeer.net')
                        || evt.url.startsWith('https://staging.nearpeer.net')
                        || evt.url.startsWith('https://teams.nearpeer.net');

                    if (isFinalRedirect) {
                        browserWindowRef.current.removeEventListener('exit', redirectToLogin);

                        browserWindowRef.current.close();

                        const params = new URLSearchParams(evt.url?.split('?')?.[1]);

                        if (params.get('redirect_to_login')) {
                            redirectToLogin();
                            return;
                        }

                        if (params.get('error')) {
                            // TODO handle the error
                            console.log('error', params.get('error'));
                            console.log('error_description', params.get('error_description'));
                        }

                        const code = params.get('code');
                        const state = params.get('state').replace(/#$/, '');

                        cordovaCallback({ code, state });
                    }
                });

                // If user closes the browser using for example the Android hardware back button,
                // redirect to login
                browserWindowRef.current.addEventListener('exit', redirectToLogin);
            }
        };

        const cordovaProps = {};

        if (window.cordova) {
            cordovaProps.openUrl = (url) => {

                openInBrowser(url);
            };
        }

        return async () => {

            const extraAuthParams = {};

            if (shouldAutoRedirect && sso?.split(',')?.length === 1) {
                if (sso === 'google') {
                    extraAuthParams.connection = 'google-oauth2';
                }
                else {
                    extraAuthParams.connection = sso;
                }
            }

            await auth0Client.loginWithRedirect({
                authorizationParams: {
                    domain,
                    client_id: clientId,
                    redirect_uri: redirectUri,
                    scope,
                    prompt: 'login',
                    userEmail: email,
                    loginMethods: sso,
                    helperText: `Login with the provider that uses your email "${email}"`,
                    ...extraAuthParams
                },
                ...cordovaProps
            });
        };
    }, []);

    return {
        client: auth0Client,
        logout,
        login,
        redirectToLogin,
        domain,
        clientId,
        scope
    };
};
