const T = require('prop-types');
const { push: Push } = require('connected-react-router');
const { makeActionCreator } = require('../utils/redux-helpers');
const { APP: Types } = require('../action-types');

const internals = {};

module.exports = (context) => {

    const api = context.api.nearpeer;
    const ipStack = context.api.ipStack;
    const actions = context.actions;

    return {
        setMainAppLoading:      makeActionCreator(Types.SET_MAIN_APP_LOADING,               { appLoading: T.bool }),
        setAppContextFilter:    makeActionCreator(Types.UPDATE_APP_CONTEXT_FILTER,      { filter: T.string }),
        setInterestsSearchText: makeActionCreator(Types.SET_INTERESTS_SEARCH_TEXT,      { interestsSearchText: T.string }),
        setDiscoverSortType:    makeActionCreator(Types.UPDATE_DISCOVER_SORT_TYPE,      { type: T.string }),
        setConnectionsFilter:   makeActionCreator(Types.UPDATE_CONNECTIONS_FILTER,      { filter: T.string }),
        setAppReviewStatus:     makeActionCreator(Types.UPDATE_APP_REVIEW_STATUS,      { status: T.string }, {
            async: api.appFeedback.updateReviewStatus
        }),
        setUserReviewStatus:            makeActionCreator(Types.SET_USER_REVIEW_STATUS,      { status: T.bool }),
        fetchUserReviewAvailableStatus: makeActionCreator(Types.FETCH_USER_REVIEW_AVAILABLE_STATUS,      {}, {
            async: api.appFeedback.fetchUserReviewAvailableStatus,
            after: ({ dispatch, payload: { result: { data: { status } } } }) => {

                dispatch(actions.app.setUserReviewStatus({
                    status
                }));
            }
        }),
        checkUserIp: makeActionCreator(Types.CHECK_USER_IP, {}, {
            async: ipStack.check,
            after: ( { dispatch, payload: { result: { data: { country_code } } } }  ) => {

                if (country_code === 'PK') {
                    dispatch(Push('/nearpeer-pakistan'));
                }
            }
        }),
        setClassmateDiscoverSearchFilter: makeActionCreator(Types.UPDATE_CLASSMATE_DISCOVER_SEARCH_FILTER,{ filter: T.object }),
        setNotifySearchFilter:      makeActionCreator(Types.UPDATE_NOTIFY_SEARCH_FILTER,     { filter: T.object }),
        setSurveySearchFilter:      makeActionCreator(Types.UPDATE_SURVEY_SEARCH_FILTER,     { filter: T.object }),
        setSurveysManagementFilter: makeActionCreator(Types.UPDATE_SURVEYS_MANAGEMENT_FILTER,{ filter: T.string }),
        setFirebaseToken:           makeActionCreator(Types.SET_FIREBASE_TOKEN,              { token: T.string }),
        setAppInBackground:         makeActionCreator(Types.SET_IN_BACKGROUND,               { inBackground: T.bool }),
        openNotifications:          makeActionCreator(Types.OPEN_NOTIFICATIONS,              {}),
        closeNotifications:         makeActionCreator(Types.CLOSE_NOTIFICATIONS,             {}),
        openLogoutDialog:           makeActionCreator(Types.OPEN_LOGOUT_DIALOG,              {}),
        closeLogoutDialog:          makeActionCreator(Types.CLOSE_LOGOUT_DIALOG,             {}),
        openAlertDialog:            makeActionCreator(Types.OPEN_ALERT_DIALOG,               {}),
        setAlertDialogMessage:      makeActionCreator(Types.SET_ALERT_DIALOG_MESSAGE,        { message: T.string }),
        closeAlertDialog:           makeActionCreator(Types.CLOSE_ALERT_DIALOG,              {}),
        setGlobalInputPageError:    makeActionCreator(Types.INPUT_PAGE_SET_ERROR,            {}),
        removeGlobalInputPageError: makeActionCreator(Types.INPUT_PAGE_REMOVE_ERROR,         {}),
        openModal:                  makeActionCreator(Types.OPEN_MODAL,                      {}),
        closeModal:                 makeActionCreator(Types.CLOSE_MODAL,                     {}),
        setHistoryPathname:         makeActionCreator(Types.SET_HISTORY_PATHNAME,            { pathname: T.string }),
        setHistoryPreviousPathname: makeActionCreator(Types.SET_HISTORY_PREVIOUS_PATHNAME,   { pathname: T.string }),
        setCollapseHeaders:         makeActionCreator(Types.SET_COLLAPSE_HEADERS,            { setting: T.bool }),
        rememberScroll:             makeActionCreator(Types.REMEMBER_SCROLL,                 { type: T.oneOf(['discover', 'group']) }, {
            propTransform: ({ type }) => {

                return { type: { [type]: internals.getScrollContainer()?.scrollTop || 0 } };
            }
        }),
        restoreScroll: makeActionCreator(Types.RESTORE_SCROLL,                               { type: T.oneOf(['discover', 'group']) }, {
            propTransform: ({ type }, { state }) => {

                if (state.app.rememberedScroll !== null && internals.getScrollContainer()) {
                    internals.getScrollContainer().scrollTop = state.app.rememberedScroll?.[type] || 0;
                }

                return { type };
            }
        }),
        setShouldRefreshSearch: makeActionCreator(Types.SHOULD_REFRESH_SEARCH,               { discover: T.bool, group: T.bool })
    };
};

// Lazy require to avoid circular dep
internals.getScrollContainer = () => require('../layouts/AppLayout').getScrollContainer();
