const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');

const { default: MuiSwitch } = require('@mui/material/Switch');
const { default: Button } = require('@mui/material/Button');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogTitle } = require('@mui/material/DialogTitle');

const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const internals = {};

module.exports = function SimpleDialog(props) {

    const {
        isOpen,
        onRequestClose,
        onConfirm,
        title,
        content: Content,
        buttons: Buttons
    } = props;

    const onClose = () => {

        AnimatedFocusIndicator.onBlurHandler();
        onRequestClose?.();
    };

    const _onConfirm = () => {

        if (onConfirm) {
            AnimatedFocusIndicator.onBlurHandler();
            onConfirm();
        }
        else {
            onClose();
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby='alert-logout-dialog-title'
            aria-describedby='alert-logout-dialog-description'
        >
            <div tabIndex={0} data-focus-outline='radius:15'>
                <DialogTitle id='alert-logout-dialog-title'>{title}</DialogTitle>
                <DialogContent sx={{ minWidth: 300 }}>
                    {React.isValidElement(Content) && Content}
                    {!React.isValidElement(Content) && <DialogContentText id='alert-logout-dialog-description'>
                        {Content}
                    </DialogContentText>}
                </DialogContent>
                <DialogActions>
                    {Buttons || (
                        <Button
                            onClick={_onConfirm}
                            data-focus-outline='radius:40'
                        >
                            Ok
                        </Button>
                    )}
                </DialogActions>
            </div>
        </Dialog>
    );
};

module.exports.propTypes = {
    isOpen: T.bool,
    onRequestClose: T.func,
    onConfirm: T.func,
    title: T.string,
    content: T.any,
    buttons: T.any
};

internals.StyledSwitch = Styled(MuiSwitch)`
    .MuiSwitch-input {
        left: 0;
        width: 100%;
    }
`;
