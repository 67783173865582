const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Classes } = require('./styles.scss');
const { default: AppBar } = require('@mui/material/AppBar');
const { default: Toolbar } = require('@mui/material/Toolbar');
const { NavLink: Link } = require('react-router-dom');
const { transient$Props } = require('utils/styles');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');
const PageTitleByPath = require('utils/pageTitleByPath');

const Logomark = require('static/nearpeer_n_white.svg');
const Footer = require('components/Footer');

const internals = {};

class AppBarLayout extends React.PureComponent {

    static propTypes = {
        children: T.element.isRequired,
        fullWidth: T.bool,
        hideFooter: T.bool,
        contentWrapperClass: T.string,
        topScrollContent: T.any,
        bottomScrollContent: T.any
    };

    render() {

        const {
            children,
            fullWidth,
            hideFooter = false,
            contentWrapperClass,
            topScrollContent,
            bottomScrollContent
        } = this.props;

        const {
            Main,
            FooterContainer
        } = internals;

        const { pathname: path } = window.location;

        const pageTitle = PageTitleByPath(path);

        return (
            <div className={Classes.wrapper} onFocus={AnimatedFocusIndicator.onFocusHandler} onBlur={AnimatedFocusIndicator.onBlurHandler}>
                <AppBar position='fixed' style={{ borderRadius: 0 }} elevation={0}>
                    <Toolbar className={Classes.toolbar}>
                        <Link to='/' className={Classes.toolbarInner}>
                            <div className={Classes.logo}>
                                <img src={Logomark} alt={'Nearpeer logomark'} className={Classes.logomark} />
                            </div>
                            <h1 aria-label={pageTitle}>Nearpeer</h1>
                        </Link>
                    </Toolbar>
                </AppBar>
                <Main
                    $footerShowing={!hideFooter}
                    className={`${Classes.main}`}
                    onScroll={() => AnimatedFocusIndicator.wrapFocusIndicatorForElement()}
                >
                    {topScrollContent}
                    <div className={Classes.mainInnerWrapper}>
                        {/* Main content */}
                        <div className={contentWrapperClass || (fullWidth ? '' : 'contentWrapper')}>
                            {children}
                        </div>
                    </div>
                    {bottomScrollContent}
                    {!hideFooter && (
                        <FooterContainer>
                            <Footer />
                        </FooterContainer>
                    )}
                    <AnimatedFocusIndicator />
                </Main>
            </div>
        );
    }
}

module.exports = AppBarLayout;

internals.Main = Styled('div', transient$Props)`

    ${({ $footerShowing }) => {

        if ($footerShowing) {
            return `
                margin-bottom: 64px;

                @media (max-width: 600px) {
                    margin-bottom: 95px;
                }
            `;
        }

        return 'flex-grow: 1;';
    }}
`;

internals.FooterContainer = Styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
`;
