const React = require('react');
const { useState, useEffect } = require('react');
const T = require('prop-types');

const { default: clsx } = require('clsx');
const { default: FAB } = require('@mui/material/Fab');
const { default: TextField } = require('@mui/material/TextField');
const { default: UploadIcon } = require('@mui/icons-material/Publish');
const { default: UploadedFileIcon } = require('@mui/icons-material/Attachment');
const BottomButtonContainer = require('components/BottomButtonContainer');
const NotifyUsersFilterDialog = require('containers/NotifyUsersFilterDialog');

const { default: Classes } = require('./styles.scss');
const FixMuiMultilineAriaLabel = require('utils/fixMui4MultilineAriaLabel');
const { default: InputLabel } = require('@mui/material/InputLabel');
const { default: SelectField } = require('@mui/material/Select');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: Typography } = require('@mui/material/Typography');
const Capitalize = require('lodash/capitalize');
const { default: FormHelperText } = require('@mui/material/FormHelperText');
const { default: FormControl } = require('@mui/material/FormControl');
const _capitalize = require('lodash/capitalize');
const IsEqual = require('lodash/isEqual');
const { useTheme } = require('@mui/material/styles');
const { default: useMediaQuery } = require('@mui/material/useMediaQuery');
const { default: Paper } = require('@mui/material/Paper');
const { default: Chip } = require('@mui/material/Chip');
const { default: Button } = require('@mui/material/Button');
const { default: SearchIcon } = require('@mui/icons-material/Search');
const { default: CancelIcon } = require('@mui/icons-material/Cancel');
const { default: Avatar } = require('@mui/material/Avatar');
const { default: Autocomplete } = require('@mui/material/Autocomplete');
const { default: ListSubheader } = require('@mui/material/ListSubheader');

const { VariableSizeList } = require('react-window');
const Copy = require('clipboard-copy');

const { default: ExpandLess } = require('@mui/icons-material/ExpandLess');
const { default: ExpandMore } = require('@mui/icons-material/ExpandMore');
const { default: Collapse } = require('@mui/material/Collapse');
const EmojiPicker = require('components/EmojiPicker');
const { default: FormControlLabel } = require('@mui/material/FormControlLabel');
const { default: Checkbox } = require('@mui/material/Checkbox');
const { default: styled } = require('styled-components');

const { EditorState, Modifier, ContentState, convertFromHTML } = require('draft-js');
const { Editor } = require('react-draft-wysiwyg');

//DRAFT-JS EeDITOR STYLES
require('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
const { stateToHTML } = require('draft-js-export-html');

const { createRef } = React;

const MB_IN_BYTES = 1000000;
const NOTIFICATION_BLAST_LIMIT = 1600;

const StyledChip = styled(Chip)`
    && {
        height: 40px;
        padding: 5px;

        &:focus {
            outline: auto 2px -webkit-focus-ring-color;
        }
    }

    .MuiChip-avatar {
        height: 36px !important;
        width: 36px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: contain;

        & svg {
            width: 100%;
            height: auto;
        }
    }
`;

const LinkToolbarComponent = ({
    expanded,
    doExpand,
    doCollapse,
    onExpandEvent,
    config,
    onChange,
    currentState,
    translations
}) => {

    const [showModal, setShowModal] = useState(true);
    const [linkTarget, setLinkTarget] = useState('');
    const [linkTargetError, setLinkTargetError] = useState(false);
    const [linkTitle, setLinkTitle] = useState('');
    const [linkTargetOption, setLinkTargetOption] = useState(
        config.defaultTargetOption
    );

    const theme = useTheme();
    useEffect(() => {

        if (!expanded) {
            setShowModal(false);
            setLinkTarget('');
            setLinkTitle('');
            setLinkTargetOption(config.defaultTargetOption);
        }
    }, [expanded, config]);

    const removeLink = () => {

        if (currentState.link){

            onChange('unlink');
        }
    };

    const addLink = () => {

        onChange('link', linkTitle, linkTarget, linkTargetOption);
    };

    const isHttpValid = (str) => {

        const urlRegex = /^(?:(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/[^\s]*)?)?$/;
        return urlRegex.test(str);

    };

    const updateValue = (event) => {

        const { name, value } = event.target;
        if (name === 'linkTitle') {
            setLinkTitle(value);
        }
        else if (name === 'linkTarget') {

            setLinkTarget(value);

            if (isHttpValid(value)){

                setLinkTargetError(false);
            }
            else {
                setLinkTargetError(true);
            }
        }
    };

    // const updateTargetOption = (event) => {

    //     setLinkTargetOption(event.target.checked ? '_blank' : '_self');
    // };

    // const hideModal = () => {

    //     setShowModal(false);
    // };

    const signalExpandShowModal = () => {

        const { link, selectionText } = currentState;
        const currentLinkTarget = (link && link.target) || '';
        const currentLinkTargetOption = (link && link.targetOption) || linkTargetOption;
        const currentLinkTitle = (link && link.title) || selectionText;

        onExpandEvent();
        setShowModal(true);
        setLinkTarget(currentLinkTarget);
        setLinkTargetOption(currentLinkTargetOption);
        setLinkTitle(currentLinkTitle);
    };

    // const forceExpandAndShowModal = () => {

    //     const { link, selectionText } = currentState;
    //     const currentLinkTargetOption = (link && link.targetOption) || linkTargetOption;
    //     const currentLinkTarget = link && link.target;
    //     const currentLinkTitle = (link && link.title) || selectionText;

    //     doExpand();
    //     setShowModal(true);
    //     setLinkTarget(currentLinkTarget);
    //     setLinkTargetOption(currentLinkTargetOption);
    //     setLinkTitle(currentLinkTitle);
    // };

    const renderAddLinkModal = () => {

        return (
            <div
                className={'rdw-link-modal'}
                onClick={(event) => event.stopPropagation()}
            >
                <label className="rdw-link-modal-label" htmlFor="linkTitle">
                    {translations['components.controls.link.linkTitle']}
                </label>
                <input
                    id="linkTitle"
                    className="rdw-link-modal-input"
                    onChange={updateValue}
                    onBlur={updateValue}
                    name="linkTitle"
                    value={linkTitle}
                />
                <label className="rdw-link-modal-label" htmlFor="linkTarget">
                    {translations['components.controls.link.linkTarget']}
                </label>
                <input
                    id="linkTarget"
                    className="rdw-link-modal-input"
                    onChange={updateValue}
                    onBlur={updateValue}
                    name="linkTarget"
                    value={linkTarget}
                />
                {linkTargetError && <span style={{ color:theme.palette.error.main }}>Enter valid URL</span>}
                <span className="rdw-link-modal-buttonsection">
                    <button
                        className="rdw-link-modal-btn"
                        onClick={addLink}
                        disabled={!linkTarget || !linkTitle || linkTargetError}
                    >
                        {translations['generic.add']}
                    </button>
                    <button className="rdw-link-modal-btn" onClick={doCollapse}>
                        {translations['generic.cancel']}
                    </button>
                </span>
            </div>
        );
    };

    const renderInFlatList = () => {

        const { options, link, unlink } = config;

        return (
            <div className={'rdw-link-wrapper'} aria-label="rdw-link-control">
                {options.indexOf('link') >= 0 && (
                    <div
                        value="unordered-list-item"
                        className={clsx(link.className,'rdw-option-wrapper')}
                        onClick={signalExpandShowModal}
                        aria-haspopup="true"
                        aria-expanded={showModal}
                        title={link.title || translations['components.controls.link.link']}
                    >
                        <img src={link.icon} alt="" />
                    </div>
                )}
                {options.indexOf('unlink') >= 0 && (
                    <div
                        disabled={!currentState.link}
                        className={clsx(unlink.className,'rdw-option-wrapper',!currentState.link && 'rdw-option-disabled' )}
                        onClick={removeLink}
                        title={
                            unlink.title || translations['components.controls.link.unlink']
                        }
                    >
                        <img src={unlink.icon} alt="" />
                    </div>
                )}
                {expanded && showModal ? renderAddLinkModal() : undefined}
            </div>
        );
    };

    const renderInDropDown = () => {

        const { title } = config;
        return (
            <div className="rdw-link-wrapper" aria-haspopup="true" aria-label="rdw-link-control" aria-expanded={expanded} title={title}>
                {/* ... rest of the content */}
            </div>
        );
    };

    return config.inDropdown ? renderInDropDown() : renderInFlatList();
};

LinkToolbarComponent.propTypes = {
    expanded: T.bool,
    doExpand: T.func,
    doCollapse: T.func,
    onExpandEvent: T.func,
    config: T.object,
    onChange: T.func,
    currentState: T.object,
    translations: T.object
};

const UserChip = (props) => {

    const {
        user
    } = props;

    const imgUrl = (user && user.croppedPicture) ? user.croppedPicture :  null;

    return (
        <StyledChip
            avatar={imgUrl && <Avatar alt={`${user.firstName} img`} src={imgUrl} srcSet={imgUrl} />}
            label={`${user.firstName} ${user.lastName}`}
            {...props}
            tabIndex={0}
            aria-label={`User ${user.firstName} ${user.lastName}`}
        />
    );
};

UserChip.propTypes = {
    user: T.object
};

const LISTBOX_PADDING = 8; // px

const renderRow = (props) => {

    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING
        }
    });
};

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef((props, ref) => {

    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {

    const ref = React.useRef(null);
    React.useEffect(() => {

        if (ref.current !== null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
};

// Adapter for react-window
// eslint-disable-next-line react/display-name
const ListboxComponent = React.forwardRef((props, ref) => {

    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {

        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {

        if (itemCount > 8) {
            return 8 * itemSize;
        }

        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: T.node
};

const StyledFormControl = styled(FormControl)`
    width: 100%;
    margin: 0.2em 0em;
`;

// const StyledMessageField = styled(TextField)(({ theme }) => ({
//     [theme.breakpoints.down('md')]:{
//         height:'70px'
//     }
// }));

module.exports = class BatchNotify extends React.PureComponent {

    static propTypes = {
        onSubmit: T.func.isRequired,
        onSubmitTest: T.func.isRequired,
        schoolId: T.number.isRequired,
        currentUserId: T.number.isRequired,
        schoolName: T.string.isRequired,
        schoolRoles: T.string.isRequired,
        schoolRoleGroups: T.string.isRequired,
        activeSearchFilter: T.object,
        searchResults: T.array,
        interestsActiveFilter_notify: T.array,
        badges: T.array,
        departments: T.array,
        officesList: T.arrayOf(
            T.shape({
                id: T.number,
                name: T.string
            })
        ),
        yearsHiredList: T.arrayOf(
            T.shape({
                id: T.number,
                year: T.number
            })
        ),
        isCompany: T.bool,
        isCommunity: T.bool,
        isSchoolOnline: T.bool,
        onClickResetSearchFilters: T.func,
        majors: T.arrayOf(T.shape({
            id: T.number,
            name: T.string
        }))
    }

    constructor(props) {

        super(props);

        this.state = {
            text: '',
            plainText: '',
            textEditorState: EditorState.createEmpty(),
            emojiSymbol: '',
            selectedRoleId: '',
            csv: null,
            searchFilterOpen: false,
            dropdownUsersOpen: true,
            usePushSystem: true,
            textCopied: false,
            selectedUsers: []
        };

        this.fields = ['csv', 'text', 'selectedRoleId','selectedUsers','emojiSymbol','usePushSystem'];
        this.requiredFields = ['text'];

        this.error = this._error.bind(this);
        this.reset = this._reset.bind(this);
        this.selectFile = this._selectFile.bind(this);
        this.setFieldValue = this._setFieldValue.bind(this);
        this.setTextEditorValue = this._setTextEditorValue.bind(this);
        this.setEmojiValue = this._setEmojiValue.bind(this);
        this.copyText = this._copyText.bind(this);
        this.setSelectValue = this._setSelectValue.bind(this);
        this.submit = this._submit.bind(this);
        this.submitTest = this._submitTest.bind(this);
        this.validate = this._validate.bind(this);
        this.closeSearchFilters = this._closeSearchFilters.bind(this);
        this.openSearchFilters = this._openSearchFilters.bind(this);
        this.renderActiveFilters = this._renderActiveFilters.bind(this);
        this.handleSelectedUsersDropdownClick = this._handleSelectedUsersDropdownClick.bind(this);
        this.toggleStateForBool = this._toggleStateForBool.bind(this);

        this.fabInputRef = createRef();
        this.notificationTextRef = createRef();
    }

    componentDidMount() {

        FixMuiMultilineAriaLabel(this.notificationTextRef.current);

        // Fix draftjs list
        const listDropdownImg = document.querySelector('.rdw-dropdown-wrapper.rdw-list-dropdown .rdw-dropdown-selectedtext img');
        listDropdownImg?.setAttribute('alt', 'List dropdown');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.searchResults !== null && !IsEqual(this.props.searchResults,prevProps.searchResults)) {
            this.setState({
                selectedUsers:this.props.searchResults
            });
        }
    }

    showError(field, show) {

        show = (typeof show === 'undefined') ? Boolean(this.validate(field)) : show;

        return () => {

            return this.setState({ [`${field}ErrorShow`]: show });
        };
    }

    _renderActiveFilters() {

        const {
            activeSearchFilter,
            badges,
            interestsActiveFilter_notify,
            departments,
            yearsHiredList,
            officesList,
            isCompany,
            isCommunity,
            isSchoolOnline,
            schoolRoleGroups,
            majors
        } = this.props;

        const { selectedRoleId } = this.state;
        const isFilteringResults =  activeSearchFilter && !(Object.keys(activeSearchFilter).length === 0) && !activeSearchFilter.allByMatches;

        let interestTitle = null;

        if (interestsActiveFilter_notify.length){
            if (interestsActiveFilter_notify.length === 1){
                interestTitle = interestsActiveFilter_notify[0].name;
            }
            else {
                interestTitle = 'Multiple Interests Chosen';
            }
        }

        let selectedBadge = null;
        if (activeSearchFilter && activeSearchFilter.badge) {

            const badgeItem = badges.find(({ name }) => {

                return name === activeSearchFilter.badge;
            });
            if (badgeItem){
                selectedBadge = badgeItem.label;
            }
        }

        let roleText = null;
        if ( activeSearchFilter && activeSearchFilter.contextFilter){

            const activeRoleGroup =  schoolRoleGroups.find((roleGroup) => roleGroup.name === activeSearchFilter.contextFilter) || null;

            roleText = activeRoleGroup ? activeRoleGroup.label : '';
        }

        let timeStatusText = null;
        if ( activeSearchFilter && activeSearchFilter.fullTimeStatus){

            timeStatusText = _capitalize(activeSearchFilter.fullTimeStatus);
        }

        let isTransferText = null;
        if ( activeSearchFilter && activeSearchFilter.isTransfer){

            isTransferText = 'No';
            if (activeSearchFilter.isTransfer === 'true'){
                isTransferText = 'Yes';
            }
        }

        let isOnlineText = null;
        if ( activeSearchFilter && activeSearchFilter.isOnline){

            isOnlineText = 'No';
            if (activeSearchFilter.isOnline === 'true'){
                isOnlineText = 'Yes';
            }
        }

        let openSocialText = null;
        if (activeSearchFilter && activeSearchFilter.openSocial) {
            openSocialText = 'No';
            if (activeSearchFilter.openSocial === 'true'){
                openSocialText = 'Yes';
            }
        }

        let veteranText = null;
        if (activeSearchFilter && activeSearchFilter.isVeteran) {

            veteranText = 'No';
            if (activeSearchFilter.isVeteran === 'true'){
                veteranText = 'Yes';
            }
        }

        let departmentText = null;
        if (activeSearchFilter && activeSearchFilter.departmentId) {
            const selectedDepartment = departments.find((item) => {

                return item.id === activeSearchFilter.departmentId;
            });

            if (selectedDepartment){
                departmentText = selectedDepartment.name;
            }
        }

        let workRemoteText = null;
        if (activeSearchFilter && activeSearchFilter.workRemote) {

            workRemoteText = 'No';
            if (activeSearchFilter.workRemote === 'true'){
                workRemoteText = 'Yes';
            }
        }

        let yearHiredText = null;
        if (activeSearchFilter && activeSearchFilter.yearHiredId) {
            const selectedYear = yearsHiredList.find((item) => {

                return item.id === activeSearchFilter.yearHiredId;
            });

            if (selectedYear){
                yearHiredText = selectedYear.year;
            }
        }

        let officeText = null;
        if (activeSearchFilter && activeSearchFilter.officeId) {
            const selectedOffice = officesList.find((item) => {

                return item.id === activeSearchFilter.officeId;
            });

            if (selectedOffice){
                officeText = selectedOffice.name;
            }
        }

        if (isFilteringResults || selectedRoleId === 'search') {
            let firstMajor = activeSearchFilter.major ? activeSearchFilter.major[0] : null;

            if (firstMajor) {
                firstMajor = majors.find(({ id }) => id === firstMajor)?.name;
            }

            const activeFilters = [
                {
                    label:'User Type',
                    value:roleText
                },{
                    label:'Name',
                    value:activeSearchFilter.name ? activeSearchFilter.name : null
                },{
                    label:'Age',
                    value:activeSearchFilter.age ? activeSearchFilter.age : null
                },{
                    label:'Career',
                    value:activeSearchFilter.career ? activeSearchFilter.career : null
                },{
                    label:'Profession',
                    value:activeSearchFilter.profession ? activeSearchFilter.profession : null
                },{
                    label:'Bio',
                    value:activeSearchFilter.bio ? activeSearchFilter.bio : null
                },{
                    label:'Department',
                    value:departmentText
                },{
                    label:isCompany ? 'Affiliated Office' : 'Campus',
                    value:officeText
                },{
                    label:'Year Hired',
                    value:yearHiredText
                },{
                    label:'Work Remote',
                    value:workRemoteText
                },{
                    label:'Title',
                    value:activeSearchFilter.title ? activeSearchFilter.title : null
                },{
                    label:'Badge',
                    value:selectedBadge
                },{
                    label:'Radius',
                    value:activeSearchFilter.radius ? activeSearchFilter.radius : null
                },{
                    label:'Location',
                    value:activeSearchFilter.locationType ? (activeSearchFilter.locationType === 'housing' ? 'Where I Live' : ((isCompany || isCommunity || isSchoolOnline) ? 'Where I Grew Up' : 'My Hometown')) : null
                },{
                    label:'Transfer Student',
                    value:isTransferText
                },{
                    label:'Online Student',
                    value:isOnlineText
                },{
                    label:'Open to Social',
                    value:openSocialText
                },{
                    label:'Veteran',
                    value:veteranText
                },{
                    label:'Full / Part-time',
                    value:timeStatusText
                },{
                    label:'Incoming Class',
                    value:activeSearchFilter.incomingClass ? activeSearchFilter.incomingClass : null
                },{
                    label:'Graduating Class',
                    value:activeSearchFilter.graduatingClass ? activeSearchFilter.graduatingClass : null
                },{
                    label: 'Major',
                    value: firstMajor || null
                },{
                    label:'Student Name',
                    value:activeSearchFilter.studentName ? activeSearchFilter.studentName : null
                },{
                    label:'Interests',
                    value: interestTitle
                }
            ];

            return (
                <Paper className={`${Classes.filterHeader}`}>
                    <h5 className={Classes.filterTitle}>Active Search Filters:</h5>
                    <div className={Classes.filtersHolder}>
                        {activeFilters.map((filter,index) => {

                            if (filter.value !== undefined && filter.value !== null && filter.value !== false){
                                return <Chip key={index} className={Classes.activeFilter} color={'primary'} label={`${filter.label} : ${filter.value}`} />;
                            }

                        })}
                    </div>
                    <div className={Classes.activeFilterBtnCont}>
                        <Button
                            variant='contained'
                            color='primary'
                            size="small"
                            onClick={this.openSearchFilters}
                            className={Classes.activeFilterBtn}
                            startIcon={<SearchIcon style={{ color:'#ffffff' }} />}
                        >
                            Edit
                        </Button>
                        <Button
                            variant='contained'
                            color='secondary'
                            size="small"
                            onClick={() => {

                                this.props.onClickResetSearchFilters();
                                this.setState({
                                    selectedRoleId:''
                                });
                            }}
                            className={Classes.activeFilterBtn}
                            startIcon={<CancelIcon style={{ color:'#ffffff' }} />}
                        >
                            Cancel
                        </Button>
                    </div>
                </Paper>
            );
        }

        return null;
    }
    _closeSearchFilters() {

        this.setState({
            searchFilterOpen:false
        });
    }
    _openSearchFilters() {

        this.setState({
            searchFilterOpen:true
        });
    }
    _error(field) {

        if (!this.state[`${field}ErrorShow`]) {
            return null;
        }

        return this.validate(field);
    }

    _selectFile(ev) {

        const newCsv = ev.target.files[0];
        if (newCsv === null) {
            return;
        }

        this.showError('csv')();
        this.setState({
            csv: newCsv
        });
        ev.target.value = null; // Reset so we can select the same file and still trigger an onChange
    }

    _setFieldValue(field) {

        return (ev, info) => {

            // Nullish operator accepts ''
            const value = info?.newValue ?? ev?.target?.value ?? ev;

            this.setState({
                [field]: value,
                textCopied: field === 'text' ? false : undefined
            });
        };
    }

    _setTextEditorValue(editorState) {


        const options = {
            entityStyleFn: (entity) => {

                const entityType = entity.get('type').toLowerCase();
                if (entityType === 'link') {
                    const data = entity.getData();

                    let safeUrl = data.url.replace('http://', 'https://');
                    if (!data.url.startsWith('http://') && !data.url.startsWith('https://')){
                        safeUrl = `https://${safeUrl}`;
                    }

                    return {
                        element: 'a',
                        attributes: {
                            href: safeUrl,
                            target:data.targetOption
                        }
                    };
                }
            }
        };

        const value = stateToHTML(editorState.getCurrentContent(),options);
        const plainText = editorState.getCurrentContent().getPlainText();

        if (editorState.getCurrentContent().getPlainText().length <= NOTIFICATION_BLAST_LIMIT){

            this.setState({
                text: value,
                plainText,
                textEditorState:editorState,
                textCopied: false
            });
        }
    }

    _toggleStateForBool(statePropName) {

        return () => {

            if (!this.state[statePropName]){
                this.setState({
                    [statePropName]: false
                });
            }

            this.setState({ [statePropName]: !this.state[statePropName] });
        };
    }

    _setEmojiValue(field) {

        return (value) => {

            this.setState({
                [field]:value
            });
        };
    }

    async _copyText() {

        const { text } = this.state;

        if (text && text.length){

            await Copy(text);
        }
    }

    _setSelectValue(field) {

        return (event, index, value) => {

            if (field === 'selectedRoleId') {
                this.showError('selectedRoleId');
            }

            this.setState({
                [field]: event.target.value
            },() => {

                if (field === 'selectedRoleId' && event.target.value === 'search'){
                    this.setState({
                        searchFilterOpen:true
                    });
                }
            });
        };
    }

    _validate(field) {

        const value = this.state[field];
        const selectedRoleId = this.state.selectedRoleId;

        if (this.requiredFields.find((f) => f === field) && (!value || (field === 'text' && !this.state.plainText.length))) {
            return Capitalize(field) + ' is required!';
        }

        switch (field) {
            case 'csv': {
                // eslint-disable-next-line no-case-declarations
                if (selectedRoleId === 'csv'){
                    const match = value && value.name.match(/\.csv$/);
                    if (match === null) {
                        return 'File must be a csv';
                    }

                    if (value.size > (4.9 * MB_IN_BYTES)) {
                        return 'csv must be under 5mb';
                    }
                }

                break;
            }

            case 'selectedRoleId': {
                if (!value || value === '') {
                    return 'Must select CSV file or role';
                }

                break;
            }

            case 'selectedUsers': {
                if ((!value || value === '' || this.state.selectedUsers.length < 1) && this.state.selectedRoleId === 'search') {
                    return 'Must select at least one user';
                }

                break;
            }
        }

        return null;
    }

    _reset() {

        this.setState({
            text: '',
            plainText: '',
            textEditorState: EditorState.createEmpty(),
            emojiSymbol: '',
            csv: null,
            selectedRoleId:'',
            // hide errors (required field errors display on submit when still on form)
            ...this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;
                return collector;
            }, {})
        });
    }

    _submit(cb) {

        const hasErrors = this.fields.some(this.validate);

        if (hasErrors) {

            // On submit attempt, all errors are fair game to display

            return this.setState(this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;
                if (this.validate(field)){
                    collector[`${field}ErrorShow`] = true;
                }

                return collector;
            }, {}));
        }

        if (this.state.selectedRoleId === 'test-notify') {
            return this.submitTest(cb);
        }

        const payload = this.fields.reduce((collector, field) => {

            if (field !== 'selectedRoleId' && field !== 'selectedUsers') {
                collector[field] = this.state[field];
            }
            else if (field === 'selectedRoleId') {

                collector.roleId = (this.state.selectedRoleId === 'csv' || this.state.selectedRoleId === 'search') ? null : this.state.selectedRoleId;

            }
            else if (field === 'selectedUsers') {

                collector.selectedUsers = this.state.selectedRoleId === 'search' ? this.state.selectedUsers.map(({ id }) => id) : null;
                collector.usedCriteria = this.state.selectedRoleId === 'search' ? {
                    ...this.props.activeSearchFilter,
                    selectedRole: this.props.activeSearchFilter.contextFilter,
                    contextFilter:null
                } : null;
            }

            return collector;
        }, {});

        // No errors?  Submit the field/values

        const { schoolId, onSubmit } = this.props;
        onSubmit({ ...payload, schoolId }, cb || (() => null));

        this.copyText();
        this.reset();
        this.setState({
            textCopied:true
        });
    }

    _submitTest(cb) {

        const hasErrors = this.fields.some(this.validate);

        if (hasErrors) {

            // On submit attempt, all errors are fair game to display

            return this.setState(this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;
                if (this.validate(field)){
                    collector[`${field}ErrorShow`] = true;
                }

                return collector;
            }, {}));
        }

        // No errors?  Submit the field/values

        const { schoolId, onSubmitTest,currentUserId } = this.props;
        const { text,emojiSymbol,usePushSystem } = this.state;
        onSubmitTest({ text,emojiSymbol, schoolId,userId:currentUserId,usePushSystem }, cb || (() => null));

        this.copyText();
        this.setState({
            textCopied:true
        });
    }

    _handleSelectedUsersDropdownClick() {

        this.setState({
            dropdownUsersOpen: !this.state.dropdownUsersOpen
        });
    }

    render() {

        const {
            schoolName,
            schoolRoles,
            searchResults
        } = this.props;

        const {
            csv,
            textEditorState,
            selectedRoleId,
            selectedUsers,
            dropdownUsersOpen,
            textCopied,
            usePushSystem
        } = this.state;

        const editorClasses = [
            Classes.textEditor,
            (!this.state.text || this.state.text === '<p><br></p>') && Classes.textEditorPlaceholder
        ].filter((x) => !!x).join(' ');

        return (
            <BottomButtonContainer
                btnLabel='Send Notification'
                onBtnClick={this.submit}
                disabled={this.state.submitting}
            >
                <h2>Send Notification</h2>
                <StyledFormControl  >
                    <InputLabel  error={this.state.selectedRoleIdErrorShow}  id="user-type-select-input-label">*Selected Role</InputLabel>
                    <SelectField
                        // SelectField has width: 256px set as inline style
                        style={{ width: '100%' }}
                        id={'user-type-select-input'}
                        inputProps={{
                            'aria-labelledby': 'user-type-select-input-label'
                        }}
                        labelId={'user-type-select-input-label'}
                        value={selectedRoleId}
                        defaultValue={selectedRoleId}
                        error={this.state.selectedRoleIdErrorShow}
                        onBlur={this.showError('selectedRoleId')}
                        onChange={this.setSelectValue('selectedRoleId')}
                        maxheight={220}
                    >
                        <MenuItem key='clear-selection' value=''>Select…</MenuItem>
                        <MenuItem key='all-selection' value='all'>All Users</MenuItem>
                        <MenuItem key='search-selection' value='search'>Search-based</MenuItem>
                        {schoolRoles.map((schoolRole) => <MenuItem key={schoolRole.id} value={schoolRole.id}>{`Role: ${schoolRole.label}`}</MenuItem>)}
                        <MenuItem key='test-me-selection' value='test-notify'>Test: Notify Me</MenuItem>
                        <MenuItem key='csv-selection' value='csv'>CSV-based</MenuItem>
                    </SelectField>
                    {this.state.selectedRoleIdErrorShow && <FormHelperText  error={this.state.selectedRoleIdErrorShow}>{this.error('selectedRoleId')}</FormHelperText>}
                </StyledFormControl>
                {selectedRoleId && selectedRoleId === 'search' && selectedUsers && <React.Fragment>
                    {this.renderActiveFilters()}
                    <Autocomplete
                        className="fullWidth"
                        multiple
                        classes={{
                            endAdornment:Classes.autocompleteEndAdornment,
                            inputRoot:Classes.autocompleteInputRoot
                        }}
                        value={selectedUsers}
                        onChange={(event, value, reason, details) => {

                            this.setState({
                                selectedUsers:value
                            },() => {

                                let hasError = false;
                                if (this.validate('selectedUsers')){
                                    hasError = true;
                                }

                                this.setState({
                                    selectedUsersErrorShow:hasError
                                });
                            });
                        }}
                        options={searchResults}
                        getOptionLabel={(option) => `${option.firstName.toString()} ${option.lastName.toString()}`}
                        isOptionEqualToValue={(option,value) => {

                            return option.id === value.id;
                        }}
                        filterSelectedOptions
                        disableCloseOnSelect
                        disableListWrap
                        ListboxComponent={ListboxComponent}
                        /*renderOption={(option) => <Typography className={Classes.interestOption} noWrap>{option.firstName}</Typography>}*/
                        renderTags={(tagValue, getTagProps) => {

                            return <div style={{ background: '#ffffff' }}>
                                <Collapse timeout="auto" in={dropdownUsersOpen} unmountOnExit>
                                    {tagValue.map((option, index) => {

                                        return <UserChip key={index} {...getTagProps({ index })} user={option} />;
                                    })}
                                </Collapse>
                            </div>;
                        }}
                        renderInput={
                            (args) => {

                                return (<TextField
                                    {...args}
                                    variant="standard"
                                    label={<div className={Classes.us} onClick={this.handleSelectedUsersDropdownClick} >{`Selected Users (${selectedUsers.length})`} {dropdownUsersOpen ? <ExpandLess /> : <ExpandMore />}</div>}
                                    id={'selected_users_input'}
                                    placeholder='Start typing to search Users...'
                                    error={this.state.selectedUsersErrorShow}
                                    helperText={this.error('selectedUsers')}
                                    disableUnderline={true}
                                />);
                            }
                        }
                    />

                </React.Fragment>}
                {selectedRoleId && selectedRoleId === 'csv' && <React.Fragment>
                    <div className={Classes.uploadFileInstructions}>
                        {`Upload a CSV file containing numeric user IDs of users to notify. Make sure the file contains only one column, with a header row named 'ID'. Please enter the notification message in the field below.`}
                    </div>
                    <div className={Classes.uploadFileInstructions}>
                        For <b>{schoolName}</b>
                    </div>
                    <div className={Classes.uploadFileWrapper} >
                        {csv ?
                            <div className={Classes.uploadedFile}>
                                <UploadedFileIcon className={Classes.uploadedFileIcon} />
                                {csv.name}
                            </div> :
                            <div className={Classes.emptyUploadArea}>

                                <label id={'container-file-upload-label'} className={Classes.uploadLabel}>
                                    CSV file required
                                    <input
                                        type='file'
                                        accept='text/csv'
                                        aria-labelledby={'container-file-upload-label'}
                                        onChange={this.selectFile}
                                        style={{ visibility: 'hidden', width: '0px', height: '0px' }}
                                    />
                                </label>

                            </div>
                        }

                        <FAB onClick={() => {

                            this.fabInputRef.current.click();
                        }}  color="primary" aria-label='upload file' id={'fab-file-upload-label'}  className={Classes.fab} >
                            <UploadIcon style={{ fill: '#ffffff' }} />
                            <input
                                type='file'
                                accept='text/csv'
                                aria-labelledby={'fab-file-upload-label'}
                                onChange={this.selectFile}
                                ref={this.fabInputRef}
                                style={{ visibility: 'hidden', width: '0px', height: '0px' }}
                            />
                        </FAB>
                        {/*<FAB className={Classes.fab} containerElement='label'>
                        <UploadIcon />
                        <input
                            type='file'
                            accept='text/csv'
                            onChange={this.selectFile}
                            style={{ visibility: 'hidden', width: '0px', height: '0px' }}
                        />
                    </FAB>*/}
                    </div>
                    {this.error('csv') && <div className={Classes.error}>{this.error('csv')}</div>}
                </React.Fragment>}

                <NotifyUsersFilterDialog
                    open={this.state.searchFilterOpen}
                    onRequestClose={this.closeSearchFilters}
                />
                <StyledFormControl  >
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={usePushSystem}
                                onChange={this.toggleStateForBool('usePushSystem')}
                                checked={!!usePushSystem}
                            />
                        }
                        labelPlacement={'end'}
                        label={'Send push notification'}
                    />
                </StyledFormControl>

                <div style={{ maxWidth:'20em',padding:'1em 0em' }}>
                    <EmojiPicker
                        onChange={this.setEmojiValue('emojiSymbol')}
                        label={`Selected Notification Emoji:`}
                        value={this.state.emojiSymbol}
                    />
                </div>

                <div>
                    <InputLabel>{`(${NOTIFICATION_BLAST_LIMIT} character limit — ${textEditorState.getCurrentContent().getPlainText().length}/${NOTIFICATION_BLAST_LIMIT})`}</InputLabel>
                    <Editor
                        placeholder='Type your notification here'
                        toolbar={{
                            options: ['inline','emoji', 'blockType', 'list', 'link'],
                            inline: {  options: ['bold', 'italic', 'underline', 'strikethrough'] },
                            blockType: { inDropdown: false,  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Code'] },
                            list: { options: ['unordered', 'ordered'] ,inDropdown: true },
                            link: {
                                showOpenOptionOnHover: false,
                                defaultTargetOption: '_blank',
                                inDropdown: false,//DON"T SET IN DROPDOWN TO TRUE BEACUSE WE DIDN'T CREATE DROPDOWN VIEW FOR OUR CUSTOM COMPONENT
                                component: LinkToolbarComponent
                            }
                        }}
                        editorState={textEditorState}
                        stripPastedStyles
                        wrapperClassName={Classes.textEditorWrapper}
                        editorClassName={editorClasses}
                        onEditorStateChange={(editorState) => {

                            this.setTextEditorValue(editorState);
                        }}
                        handlePastedText={(text) => {

                            const { textEditorState: _textEditorState } = this.state;

                            const contentState = _textEditorState.getCurrentContent();

                            const blocksFromHTML = convertFromHTML(text);

                            const newContentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
                            //const pastedBlocks = ContentState.createFromText(text).getBlockMap();

                            const newState = Modifier.replaceWithFragment(
                                contentState,
                                _textEditorState.getSelection(),
                                newContentState.getBlockMap()
                            );
                            //const appendedContentState = Modifier.insertText(contentState, _textEditorState.getSelection(), newContentState.getPlainText());

                            this.setTextEditorValue(EditorState.push(_textEditorState, newState, 'insert-fragment'));

                            return true;
                        }}
                    />
                    {this.state.textErrorShow ? <Typography color={'error.main'}>{this.error('text')}</Typography> : null}
                    {textCopied ? <p>Notification saved to your clipboard. You may Paste above to resend.</p> : null}
                </div>
            </BottomButtonContainer>
        );
    }
};
