const React = require('react');
const { useEffect } = require('react');
const { useState } = require('react');
const { default: Styled } = require('styled-components');
const UniqueId = require('lodash/uniqueId');
const IsEqual = require('lodash/isEqual');

const { default: Typography } = require('@mui/material/Typography');

const BottomButtonContainer = require('components/BottomButtonContainer');
const PreventNavigationDialog = require('components/PreventNavigationDialog');
const { analyticsTemplates } = require('utils/analytics');

const InterestsInputField = require('containers/InterestsInputField');
const { INTERESTS } = require('utils/constants');
const T = require('prop-types');
const Loader = require('components/Loader');

const internals = {};

const EditInterests = (props) => {

    const { Wrapper, FieldsWrapper } = internals;
    const { history, signupDetails, interestsLoading } = props;

    const fields = ['interests', 'passionInterests'];

    const uniqueId = UniqueId('user-inputs-');

    const [pageState, setPageState] = useState({
        interestsErrorShow: false,
        interests: ((signupDetails && signupDetails.interests) ? signupDetails.interests : []).slice(),
        passionInterests: ((signupDetails && signupDetails.passionInterests) ? signupDetails.passionInterests : []).slice()
    });

    const [pageHasErrors, setPageHasErrors] = useState(false);
    const [pageIsBlocking, setPageIsBlocking] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [successRedirectPath, setSuccessRedirectPath] = useState('/app/welcome');

    useEffect(() => {

        if (props.location && props.location.state) {
            if (props.location.state.returnTo) {
                setSuccessRedirectPath(props.location.state.returnTo);
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (signupDetails.interests) {
            if (!IsEqual(signupDetails.interests,pageState.interests)) {
                setPageState({
                    interests: signupDetails.interests,
                    passionInterests: signupDetails.passionInterests
                });
            }
        }
    }, [interestsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    const onInterestsChange = (ev, value) => {

        if (value === null) {
            value = [];
        }

        setPageState((prevState) => ({
            ...prevState,
            interests: value
        }));

        setPageIsBlocking(true);
    };

    const onPassionInterestsChange = (value) => {

        if (value === null) {
            value = [];
        }

        setPageState((prevState) => ({
            ...prevState,
            passionInterests: value
        }));

        setPageIsBlocking(true);
    };

    const getSignupDetails = () => {

        const details = fields.reduce((collector, field) => {

            let fieldValue = pageState[field];

            // Force the values to be null instead of emptystring
            if (fieldValue === '') {
                fieldValue = null;
            }

            collector[field] = fieldValue;

            return collector;
        }, {});

        return {
            ...details
        };
    };

    const validate = (field) => {

        const value = pageState[field];

        switch (field) {
            case 'interests':

                if (!value.length) {
                    return 'Start typing to find and select at least one Interest';
                }

                if (value.length > INTERESTS.LIMIT) {
                    return `Sorry, you have exceeded the maximum number of ${INTERESTS.LIMIT} Interests. Please remove some before adding new Interests`;
                }

                break;
        }

        return null;
    };

    const submit = () => {

        const hasErrors = fields.some(validate);

        if (hasErrors) {

            setPageHasErrors(true);

            // On submit attempt, all errors are fair game to display
            fields.forEach((field) => {

                let fieldErrorShow = false;
                if (validate(field)){
                    fieldErrorShow = true;
                }

                setPageState((prevState) => ({
                    ...prevState,
                    [`${field}ErrorShow`]: fieldErrorShow
                }));
            });

            return props.onInvalidSubmit();
        }

        setSubmitting(true);
        setPageHasErrors(false);
        setPageIsBlocking(false);

        props.onSubmit(getSignupDetails(),successRedirectPath).then(() => {

            setSubmitting(false);
            setPageHasErrors(false);
            setPageIsBlocking(false);
        });
    };

    const submitNoRedirect = () => {

        const hasErrors = fields.some(validate);

        if (hasErrors) {

            setPageHasErrors(true);
            // On submit attempt, all errors are fair game to display
            fields.forEach((field) => {

                let fieldErrorShow = false;
                if (validate(field)){
                    fieldErrorShow = true;
                }

                setPageState((prevState) => ({
                    ...prevState,
                    [`${field}ErrorShow`]: fieldErrorShow
                }));
            });

            return props.onInvalidSubmit();
        }

        // No errors?  Submit the field/values

        setSubmitting(true);
        setPageHasErrors(false);
        setPageIsBlocking(false);

        props.onSubmitNoRedirect(getSignupDetails()).then(() => {

            setSubmitting(false);
            setPageHasErrors(false);
            setPageIsBlocking(false);
        });
    };

    const onBottomButtonClick = (ev) => {

        analyticsTemplates.buttons('save my interests', 'my interests: save my interests request');
        submit(ev);
    };

    const onBottomButtonClickNoRedirect = (ev) => {

        analyticsTemplates.buttons('save my interests', 'my interests: save my interests request');
        submitNoRedirect(ev);
    };

    const sortInterests = (a, b) => {

        const aSpecial = a && a.special;
        const bSpecial = b && b.special;

        return (Number(aSpecial) - Number(bSpecial )) || a.categoryName.localeCompare(b.categoryName) ||  a.name.localeCompare(b.name);
    };

    const error = (field) => {

        const fieldError = validate(field);

        if (fieldError !== null) {

            return <div id={uniqueId + '-' + field + '-error-message'} role='alert' aria-live='assertive'>{fieldError}</div>;
        }

        return '';
    };

    const fieldHasError = (field) => {

        if (validate(field) !== null) {

            return true;
        }

        return false;
    };

    const hasError = (field) => {

        if (fieldHasError(field)) {
            setPageHasErrors(true);
        }
    };

    const showError = (field, show) => {

        show = (typeof show === 'undefined') ? hasError(field) : show;

        const fieldName = `${field}ErrorShow`;

        setPageState((prevState) => ({
            ...prevState,
            [fieldName]: show
        }));
    };

    const renderInterestsField = () => {

        const { interests, passionInterests } = pageState;

        return <InterestsInputField
            values={!interestsLoading ? interests.sort(sortInterests) : []}
            passionValues={!interestsLoading ? passionInterests.sort(sortInterests) : []}
            onChange={onInterestsChange}
            onChangePassion={onPassionInterestsChange}
            onBlur={() => showError('interests')}
            showError={!!fieldHasError('interests')}
            errorText={error('interests')}
            isSignUpPage={false}
            isSearch={false}
            disableClearable
        />;
    };

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!interestsLoading) {
        return <Loader/>;
    }

    return (
        <Wrapper>
            <BottomButtonContainer
                btnLabel='Save'
                onBtnClick={onBottomButtonClick}
                disabled={submitting}
            >
                <PreventNavigationDialog
                    // When should shouldBlockNavigation be invoked,
                    // simply passing a boolean
                    // (same as "when" prop of Prompt of React-Router)
                    saveFunction={onBottomButtonClickNoRedirect}
                    alertMsg={'You have made changes.\n Do you want to save or discard them?'}
                    when={pageIsBlocking}
                    // Navigate function
                    navigate={(path,state) => history.push(path,state)}
                    // Use as "message" prop of Prompt of React-Router
                    shouldBlockNavigation={(location) => {

                        if (pageIsBlocking || pageHasErrors) {
                            return true;
                        }

                        return false;
                    }}
                    pageHasErrors={pageHasErrors}
                />
                <FieldsWrapper>
                    <Typography
                        variant='h2'
                        style={{ marginBottom: 10 }}
                    >
                        Edit Interests & Passions
                    </Typography>
                    {renderInterestsField()}
                </FieldsWrapper>
            </BottomButtonContainer>
        </Wrapper>
    );
};

EditInterests.propTypes = {
    onSubmit: T.func.isRequired,
    onSubmitNoRedirect:T.func.isRequired,
    onInvalidSubmit: T.func.isRequired,
    interestsLoading: T.bool,
    signupDetails: T.shape({
        interests: T.array,
        passionInterests: T.array
    }),
    history: T.object.isRequired,
    location : T.object
};

internals.Wrapper = Styled.div`
    overflow: hidden;
    height: 100%;
`;

internals.FieldsWrapper = Styled.div`
    padding: 1.5em;
    margin-top: 1rem;
`;

module.exports = EditInterests;
