const React = require('react');
const { useEffect } = require('react');
const T = require('prop-types');
const { useForm } = require('react-hook-form');
const { analyticsTemplates } = require('utils/analytics');

const Yup = require('yup');
const { yupResolver } = require('@hookform/resolvers/yup');

const { default: Classes } = require('./styles.scss');

const BottomButtonContainer = require('components/BottomButtonContainer');
const SurveyForm = require('../SurveyForm');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: ArrowBack } = require('@mui/icons-material/ArrowBack');
const { default: Tooltip } = require('@mui/material/Tooltip');
const Moment = require('moment/moment');

const PreventNavigationDialog = require('components/PreventNavigationDialog');
const IsEqual = require('lodash/isEqual');
const { usePrevious } = require('../../../../../hooks/usePrevious');



const defaultValues = {
    title:'',
    description:'',
    notifyUsers:true,
    manuallyStop:false,
    startDate:Moment().format('MM/DD/YYYY'),
    endDate:Moment().add(1,'days').add(1,'month').format('MM/DD/YYYY'),
    isActive:false,
    startImmediately:false,
    selectedRole:'',
    selectedUsers:[],
    questions:[]
};

const schema = Yup.object().shape({
    questions: Yup.array().of(
        Yup.object().shape({
            text: Yup.string().required('Question text is required'),
            answerType: Yup.mixed().oneOf(['freeInput', 'select']),
            answers: Yup.array().of(
                Yup.object().shape({
                    text: Yup.string().required('Answer text is required')
                })
            ).when('answerType', (answerType) => {

                if (answerType === 'select') {
                    return Yup.array().of(
                        Yup.object().shape({
                            text: Yup.string().required('Answer text is required')
                        })
                    ).required('At least one answer is required');
                }
            })
        })
    ).min(1, 'You need at least one question').required('At least one question is required'),
    title: Yup.string().required('Survey title is required'),
    description: Yup.string(),
    notifyUsers: Yup.boolean(),
    manuallyStop: Yup.boolean(),
    startImmediately: Yup.boolean(),
    isActive: Yup.boolean(),
    selectedRole: Yup.string().required('Must select role or search based'),
    selectedUsers: Yup.array().when('selectedRole', (selectedRole) => {

        if (selectedRole === 'search') {
            return Yup.array().min(1, 'You need to select at least one user').required('At least one user is required');
        }
    }),
    startDate: Yup.date().typeError('The value must be a date (MM/DD/YYYY)').required('Survey Start Date is required'),
    endDate: Yup.mixed().when('manuallyStop',(manuallyStop) => {

        if (manuallyStop === false) {
            return Yup.date().typeError('The value must be a date (MM/DD/YYYY)').required('Survey End Date is required');
        }
    })
});

const SurveyCreatePage = (props) => {

    // eslint-disable-next-line no-empty-pattern
    const {
        goBack,
        onSubmit,
        onClickResetSearchFilters,
        activeSearchFilter,
        interestsActiveFilter_survey,
        badges,
        departments,
        yearsHiredList,
        officesList,
        isCompany,
        isCommunity,
        isSchoolOnline,
        schoolRoles,
        schoolRoleGroups,
        searchResults,
        history,
        majors
    } = props;

    const { control, handleSubmit, formState: { errors,isDirty,isValid,isSubmitting,isSubmitSuccessful },watch,setValue,reset } = useForm({
        defaultValues,
        mode: 'onChange',
        criteriaMode: 'all',
        resolver:yupResolver(schema)
    });

    const onSubmitForm = (data) => {

        analyticsTemplates.buttons('save new survey', 'surveys: create new survey request');
        onSubmit( {
            ...data,
            startDate:Moment(data.startDate),
            endDate:Moment(data.endDate)
        });
    };

    const watchStartDate = watch('startDate');
    const watchEndDate = watch('endDate');
    const manuallyStopVal = watch('manuallyStop');

    const prevSearchResult = usePrevious(searchResults);

    if (Moment(watchStartDate).isAfter(Moment(watchEndDate)) && !manuallyStopVal) {
        setValue('endDate',Moment(watchStartDate).add(1,'days').format('MM/DD/YYYY'));
    }

    useEffect(() => {

        if (searchResults !== null  && !IsEqual(searchResults,prevSearchResult)) {
            setValue('selectedUsers',searchResults);
        }
    }, [searchResults]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BottomButtonContainer
            btnLabel='Save'
            onBtnClick={handleSubmit(onSubmitForm)}// submit functions
            disabled={isSubmitting || isSubmitSuccessful}
        >
            <PreventNavigationDialog
                // When should shouldBlockNavigation be invoked,
                // simply passing a boolean
                // (same as "when" prop of Prompt of React-Router)
                saveFunction={handleSubmit(onSubmitForm)}// submit functions
                alertMsg={'You have made changes.\n Do you want to save or discard them?'}
                when={isDirty && !isSubmitSuccessful}
                // Navigate function
                navigate={(path,state) => history.push(path,state)}
                // Use as "message" prop of Prompt of React-Router
                shouldBlockNavigation={(location) => {

                    if ((isDirty || !isValid) && !isSubmitSuccessful) {
                        return true;
                    }

                    return false;
                }}
                pageHasErrors={!isValid}
            />
            <div className={Classes.wrapper}>
                <div className={Classes.innerWrapper}>

                    <div className={Classes.topRow}>
                        <Tooltip title={'Go Back'} arrow={true} placement={'top'}>
                            <IconButton onClick={goBack} aria-label='previous screen' size="large"><ArrowBack /></IconButton>
                        </Tooltip>

                        <h2>New Survey</h2>
                    </div>
                    <SurveyForm
                        control={control}
                        setValue={setValue}
                        reset={reset}
                        errors={errors}
                        watch={watch}
                        onClickResetSearchFilters={onClickResetSearchFilters}
                        activeSearchFilter={activeSearchFilter}
                        interestsActiveFilter_survey={interestsActiveFilter_survey}
                        badges={badges}
                        searchResults={searchResults}
                        departments={departments}
                        officesList={officesList}
                        yearsHiredList={yearsHiredList}
                        isCompany={isCompany}
                        isCommunity={isCommunity}
                        isSchoolOnline={isSchoolOnline}
                        schoolRoles={schoolRoles}
                        schoolRoleGroups={schoolRoleGroups}
                        majors={majors}
                    />
                </div>
            </div>
        </BottomButtonContainer>
    );
};

SurveyCreatePage.propTypes = {
    goBack:T.func,
    onSubmit:T.func,
    onClickResetSearchFilters:T.func,
    activeSearchFilter:T.object,
    interestsActiveFilter_survey:T.func,
    badges:T.array,
    searchResults:T.array,
    departments:T.array,
    officesList: T.arrayOf(
        T.shape({
            id: T.number,
            name: T.string
        })
    ),
    yearsHiredList: T.arrayOf(
        T.shape({
            id: T.number,
            year: T.number
        })
    ),
    isCompany: T.bool,
    isCommunity: T.bool,
    isSchoolOnline: T.bool,
    schoolRoles: T.array,
    schoolRoleGroups: T.array,
    history: T.object,
    majors: T.arrayOf(T.shape({
        id: T.number,
        name: T.string
    }))
};

module.exports = SurveyCreatePage;
